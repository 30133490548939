import { FetcherResponse } from "interfaces/fetcher";
import { axiosInstance } from "utils/fetcher";

import { DataSelectFilterRequest } from "./interfaces";

const getDataSelectFilter = async (params: DataSelectFilterRequest): Promise<any> => {
    const { url, ...rest } = params

    const response = await axiosInstance.get<FetcherResponse<any>>(`${url}`, { params: rest });

    return response?.data
}

export default getDataSelectFilter