import { FieldsProp } from 'components/filter/filter';
import { TypeKeyFormItem } from 'components/filter/filter-field/fields';

export const filterFields: FieldsProp[] = [
    {
        id: 'keyword',
        key: '',
        label: 'Search Keyword',
        type: TypeKeyFormItem.Compact,
        url: undefined,
        mode: undefined,
        compactKey: ['search_input', 'search_keyword'],
        compact: [
            {
                id: 'search_input',
                label: '',
                placeholder: 'Choose Type',
                key: 'search_input',
                type: TypeKeyFormItem.Select,
                defaultValue: 'name',
                options: [{ value: 'name', label: 'Name' }]
            },
            {
                id: 'search_keyword',
                label: 'Search Keyword',
                key: 'search_keyword',
                placeholder: 'Enter Keyword',
                type: TypeKeyFormItem.Input
            }
        ]
    },
    {
        id: 'category_id',
        label: 'Category',
        key: 'category_id',
        type: TypeKeyFormItem.SelectCheckbox,
        url: 'api/v1/actual-categories?page=1&limit=-1',
        fieldsName: {
            value: 'id',
            label: 'name'
        }
    }
];
