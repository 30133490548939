import React from 'react'
import useConfirmDialog from 'hooks/use-confirm-dialog'

import styles from './style.module.scss'

const ConfirmDialog = () => {
    const { confirmDialogData } = useConfirmDialog()


    return <div className={confirmDialogData.isShow ? styles.container : styles.hide}>
        <div className={styles.content}>
            <div className={styles.messageText}>
                {confirmDialogData.title ? confirmDialogData.title : 'Confirm  Delete?'}
            </div>
            {
                confirmDialogData.description &&
                <p className={styles.messageDescription}>
                    {confirmDialogData.description}
                </p>
            }
            <div className={styles.buttonContainer}>
                <button
                    className='btn btn-danger me-2'
                    onClick={confirmDialogData.onCancel}>
                    {confirmDialogData.buttonCancelLabel}
                </button>
                <button
                    className='btn btn-primary'
                    onClick={confirmDialogData.onOk}>
                    {confirmDialogData.buttonOkLabel}
                </button>
            </div>
        </div>

    </div>

}

export default ConfirmDialog