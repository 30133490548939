import { useMemo, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FaAnglesLeft } from "react-icons/fa6";
import { Card, Skeleton, Tag, Typography } from 'antd';
import { KeyAllNotSearch } from 'components/filter/components/select-checkbox';
import { useFilterContext } from 'components/filter/context/filter-context';
import { FieldsProp } from 'components/filter/filter';
import { TypeKeyFormItem } from 'components/filter/filter-field/fields';
import { snakeToTitleCase, toSnakeCaseString } from 'components/filter/helpers/transform-key';
import { isEmpty } from 'lodash';

import { QueryValues } from '..';

import './styles.css';

enum DisplayType {
    Tag = 'tag',
    String = 'string'
}

type Props = {
    q?: QueryValues
    field?: FieldsProp
    queryValues: QueryValues[]
    onRemove?: (values: { key?: string, value?: string, options?: any }) => void
}

type Options = {
    type: DisplayType
    value: string
    label: string
    indexMax: number
    isMaxTag: boolean
    labelMax?: string
}
const { Paragraph } = Typography;
const style = { fontSize: 14, marginRight: 4, lineHeight: '22px', display: 'inline-flex' }
const defaultIndexMax = 3
const DisplayItem = ({ q, field, queryValues, onRemove }: Props) => {
    const ELSE = KeyAllNotSearch.ElseSearch
    const { key, value = [] } = q || {}
    const { label, url, type, dataSources, compactKey, compact } = field || {}
    const [indexMax, setIndexMax] = useState<"Infinity" | number>(defaultIndexMax)
    const elseSearch = queryValues?.find((q) => q.key === ELSE && q?.value?.includes(key as string))
    const { selectOptions } = useFilterContext()

    const fieldOptions = useMemo(() => {
        return selectOptions?.find((v) => v?.field?.id === field?.id)
    }, [selectOptions])

    const [ellipsis, setEllipsis] = useState(true)

    const data = useMemo(() => {
        return fieldOptions?.options || []
    }, [fieldOptions])

    const options = useMemo(() => {
        const initData = url ? data : dataSources
        if (type === TypeKeyFormItem.Compact && compactKey?.length === 1 && !isEmpty(compact)) {
            return value?.map((d: any) => {
                const temp = [] as string[]
                compact?.forEach((c, index) => {
                    const newD = d.split(',')[index]
                    if (c?.type === TypeKeyFormItem.Select && c?.options?.length > 0) {
                        temp.push(c?.options?.find((v: any) => v?.value == newD)?.label || newD)
                    } else {
                        temp.push(newD)
                    }
                })

                return {
                    type: DisplayType.String,
                    value: d,
                    label: temp.join(''),
                }
            })
        }

        if (isEmpty(initData)) return [{
            type: DisplayType.String,
            value: value,
            label: value[0],
        }]

        return value.map((d: any, index) => {
            if (d === KeyAllNotSearch.AllValue) {
                return {
                    type: DisplayType.Tag,
                    value: d,
                    label: d,
                    indexMax: indexMax,
                    isMaxTag: false
                }
            } else {
                const i = initData?.find((v: any) => v?.value == d)
                const isMax = indexMax === "Infinity" ? false : index > indexMax

                return {
                    type: DisplayType.Tag,
                    value: i?.value,
                    label: i?.label,
                    indexMax: indexMax,
                    isMaxTag: isMax,
                    labelMax: isMax ? `+ ${value.length - 4} ...` : undefined
                }
            }
        })
    }, [data, value, indexMax, dataSources, type, compactKey, compact]) as Options[]

    if ([ELSE].includes(key as any)) return null

    return (
        <Card
            size='small'
            styles={{
                body: {
                    padding: 4, marginRight: 16
                }
            }}
            className='card-item'
            style={{ background: '#efeff1' }}
        >
            <div
                style={{
                    position: 'absolute',
                    right: 2,
                    top: 1,
                    width: 18,
                    height: 18,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
            >
                <AiOutlineClose
                    size={16}
                    color='#00000073'
                    className='item-close-icon'
                    onClick={() => onRemove?.({ key: key, options: q })}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <span
                    style={{ fontWeight: 500, textTransform: 'uppercase', marginRight: 4 }}
                >
                    {
                        isEmpty(compactKey) ? label : snakeToTitleCase(toSnakeCaseString(key))
                    }:
                </span>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {(!fieldOptions || !!fieldOptions?.loading) ?
                        Array.from({ length: 1 }).map((_, index) => (
                            <Skeleton.Input key={index} active={true} style={{ height: 22 }} />
                        ))
                        :
                        options.map((v, index) => {
                            const { value, label, indexMax, labelMax, type } = v || {}

                            if (type === DisplayType.String) return (
                                <div key={index}>
                                    <Paragraph
                                        ellipsis={{
                                            rows: 1,
                                            expandable: true,
                                            expanded: !ellipsis,
                                            onExpand() {
                                                setEllipsis(!ellipsis)
                                            },
                                            defaultExpanded: false,
                                            symbol: 'more'
                                        }}
                                        style={{ marginBottom: 0, maxWidth: ellipsis ? 200 : '100%' }}
                                    >
                                        {label}
                                        {!ellipsis && (
                                            <span
                                                onClick={() => setEllipsis(!ellipsis)}
                                                style={{ color: '#1677ff', cursor: 'pointer', marginInlineStart: 4 }}
                                            >
                                                less
                                            </span>
                                        )}
                                    </Paragraph>
                                </div>
                            )

                            if (index > indexMax + 1) return null
                            const color = !isEmpty(elseSearch) ? 'red' : KeyAllNotSearch.AllValue === label ? 'blue' : 'default'
                            if (KeyAllNotSearch.AllValue === label && elseSearch?.key === ELSE) {
                                return (
                                    <div key={index}>
                                        <Tag
                                            closable={false}
                                            style={style}
                                            className={labelMax ? 'tag-max' : ''}
                                            onClick={labelMax ? () => setIndexMax("Infinity") : undefined}
                                            color='blue'
                                        >
                                            <span>
                                                ALL
                                            </span>
                                        </Tag>
                                        <Tag
                                            closable={false}
                                            style={style}
                                            color={color}
                                        >
                                            <span>
                                                NOT
                                            </span>
                                        </Tag>
                                    </div>
                                )
                            } else {
                                return (
                                    <Tag
                                        key={value}
                                        closable={!labelMax}
                                        style={style}
                                        onClose={() => onRemove?.({ key: key, value: value })}
                                        className={labelMax ? 'tag-max' : ''}
                                        onClick={labelMax ? () => setIndexMax("Infinity") : undefined}
                                        color={color}
                                    >
                                        <span>
                                            {labelMax ?? label}
                                        </span>
                                    </Tag>
                                )
                            }
                        })
                    }
                    {indexMax === "Infinity" &&
                        <div className='angles-icon' onClick={() => setIndexMax(defaultIndexMax)}>
                            <FaAnglesLeft />
                        </div>
                    }
                </div>
            </div>
        </Card>
    )
}

export default DisplayItem