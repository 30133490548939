import React, { memo } from 'react'

import { FilterProvider } from './context/filter-context'
import FilterComponent, { FieldsProp } from './filter'

export type MemoizedFilter = {
    onSubmit?: (values?: any) => void
    col?: number
    styles?: {
        button?: React.CSSProperties
    }
    fields: FieldsProp[]
}

const MemoizedFilter = (props: MemoizedFilter) => {

    return (
        <FilterProvider>
            <FilterComponent {...props} />
        </FilterProvider>
    )
}

const Filter = memo(MemoizedFilter);

export default Filter