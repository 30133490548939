import React from 'react';
import { Statistic } from 'antd/lib';

type Props = {
    from?: number;
    to?: number;
    total?: number;
};

const ShowingResultTable = ({ from, to, total }: Props) => {
    return (
        <div style={{ display: 'flex', fontSize: '14px', marginBottom: '5px', fontWeight: 600 }}>
            Showing&nbsp;
            <Statistic
                valueStyle={{
                    fontSize: 14,
                    fontWeight: '600'
                }}
                value={from || 0}
            />
            &nbsp; to&nbsp;
            <Statistic
                valueStyle={{
                    fontSize: 14,
                    fontWeight: '600'
                }}
                value={to || 0}
            />
            &nbsp; of&nbsp;
            <Statistic
                valueStyle={{
                    fontSize: 14,
                    fontWeight: '600'
                }}
                value={total || 0}
            />
            &nbsp; results
        </div>
    );
};

export default ShowingResultTable;
