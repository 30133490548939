import axios from 'axios'
import { API_UPLOAD_URL, UPLOAD_TOKEN } from 'components-v2/constants'


export type CreateTemplatesParams = {
    name?: string,
    mockup_file?: any,
    number_mockups?: number,
    enable?: boolean,
    layers?: any
}

const createTemplates = async (params: CreateTemplatesParams): Promise<any> => {
    const data = await axios.post(
        `${API_UPLOAD_URL}api/v1/catalog-mockup-templates`,
        params,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${UPLOAD_TOKEN}`
            }
        })

    return data.data
}

export default createTemplates