import { useEffect, useRef, useState } from 'react';
import { Pagination, Statistic, Table } from 'antd';
import ShowingResultTable from 'components/common/showing-result-table';
import { useTemplatesContext } from 'modules/template/core/templates-provider';

type Props = {
    data: any
    columns: any
    updateQueryTable: any
    tableLoading?: boolean
    isFetched?: boolean
    isLoading?: boolean
}
const TableComponent = ({ data, columns, updateQueryTable, tableLoading, isFetched, isLoading }: Props) => {
    const [isSelectAll, setIsSelectAll] = useState(false);
    const refPagination = useRef<any>(data?.paging)
    const refData = useRef<any>(data?.data)

    const pagination = !isLoading ? data?.paging : refPagination?.current
    const dataSource = !isLoading ? data?.data : refData.current
    const value = useTemplatesContext();

    useEffect(() => {
        if (data?.paging) {
            refPagination.current = data?.paging
        }
    }, [isLoading])

    useEffect(() => {
        if (data?.paging && isFetched) {
            refData.current = data?.data
        }
    }, [refData, isFetched])

    const handleOnChangPagination = (page: number, pageSize: number) => {
        if (refPagination.current) {
            refPagination.current.current_page = page
            refPagination.current.per_page = pageSize
        }
        updateQueryTable({
            page: page,
            limit: pageSize,
        });
    };

    const rowSelection = {
        selectedRows: value.row.selectedRows,
        selectedRowKeys: value.row.selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setIsSelectAll(selectedRows.length === data?.paging?.total);
            value.setRow({
                selectedRowKeys,
                selectedRows
            });
        }
    };

    return (
        <>
            <div className="my-2 d-flex justify-content-between">
                <ShowingResultTable from={pagination?.from} to={pagination?.to} total={pagination?.total} />

                <Pagination
                    disabled={isLoading}
                    total={pagination?.total}
                    onChange={handleOnChangPagination}
                    pageSize={pagination?.per_page || 10}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100, 200, 400]}
                    current={pagination?.current_page}
                    itemRender={(page: any, type: any, originalElement: any) => {
                        return type === 'page' ? (
                            <Statistic
                                valueStyle={{
                                    fontSize: 14,
                                    lineHeight: '30px'
                                }}
                                className="d-inline"
                                value={page}
                            />
                        ) : (
                            originalElement
                        );
                    }}
                />
            </div>
            <Table scroll={{ x: 1000 }}
                rowKey='id'
                size="small"
                loading={tableLoading}
                dataSource={!!data?.data && data?.data?.length > 0 ? data?.data : dataSource}
                columns={columns}
                pagination={false}
                rowSelection={rowSelection}
                bordered
                title={() => (
                    <span className="d-flex">
                        Row selected:&nbsp;
                        <Statistic
                            valueStyle={{
                                fontSize: 14,
                                fontWeight: '600'
                            }}
                            value={isSelectAll ? data?.paging?.total : value.row.selectedRows?.length}
                        />
                    </span>
                )}
            // scroll={{ x: "100vw" }}
            />
            <div className="my-2 d-flex justify-content-end">
                <Pagination
                    disabled={isLoading}
                    total={pagination?.total}
                    onChange={handleOnChangPagination}
                    pageSize={pagination?.per_page || 10}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    current={pagination?.current_page}
                    itemRender={(page: any, type: any, originalElement: any) => {
                        return type === 'page' ? (
                            <Statistic
                                valueStyle={{
                                    fontSize: 14,
                                    lineHeight: '30px'
                                }}
                                className="d-inline"
                                value={page}
                            />
                        ) : (
                            originalElement
                        );
                    }}
                />
            </div>
        </>
    )
}

export default TableComponent