import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from "./styles.module.scss"

type Props = {
  spin?: boolean
  spinStyle?: 1 | 2 | 3
  style?: React.CSSProperties
  text?: string
}
const LoadingComponent = (props: Props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


  return (
    <div className={styles.loadingComponent}>
      <Spin size={"large"} indicator={antIcon} />
    </div>
  )
}

export default LoadingComponent
