import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Typography } from 'antd/lib';
import { usePageData } from 'contexts/page-data';
import { LeftOutlined } from '@ant-design/icons';

import styles from './style.module.scss';

const { Title } = Typography;

const DefaultTitle: FC = () => {
  const { pageTitle, showGoBack } = usePageData();
  const navigate = useNavigate();

  return (
    <div className="w-100">
      {/* begin::Title */}
      {pageTitle && (
        <Title level={2} className={styles.pageTitle} ellipsis={{ rows: 1 }} style={{ marginBottom: '0' }}>
          {!!showGoBack && (
            <Tooltip title="Go back">
              <LeftOutlined onClick={() => navigate(-1)} />
            </Tooltip>
          )}
          {pageTitle}
        </Title>
      )}
      {/* end::Title */}
    </div>
  );
};

export { DefaultTitle };
