import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'modules/auth/contexts/auth-context';

import AppRoutes from './routing/app-routes';

import './App.css';
import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/sass/_variable.scss';

function App() {
    return (
        <div>
            <AuthProvider>
                <AppRoutes />
            </AuthProvider>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} theme="light" pauseOnHover />
        </div>
    );
}

export default App;
