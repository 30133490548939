import { createContext, useCallback, useContext, useMemo } from 'react'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useGetActualCategoryList from 'data/hooks/catalog/use-get-actual-category';
import { BasicRequestList } from 'interfaces/fetcher';
import _identity from 'lodash/identity';
import _pickBy from 'lodash/pickBy';

type QueryRequestContextProps = {
  query: BasicRequestList & { using?: string },
  updateQuery: (updateData: Partial<BasicRequestList & { using: string }>) => void
  resetFilter: () => void
  actualCategory?: any[]
}
const QueryRequestContext = createContext<QueryRequestContextProps>({
  query: { page: 1, limit: 10 },
  updateQuery: () => { },
  resetFilter: () => { },
  actualCategory: []
})

const QueryRequestProvider = ({ children }: any) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation()
  const fetchActualCategory = useGetActualCategoryList({ page: 1, limit: -1 })

  const actualCategory = useMemo(() => {
    return fetchActualCategory?.data || []
  }, [fetchActualCategory.data])

  const query: BasicRequestList = useMemo(() => {
    const paramsObj = Object.fromEntries(searchParams.entries());

    return {
      page: searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1,
      limit: searchParams.get('limit') ? parseInt(searchParams.get('limit') as string) : 10,
      ...paramsObj
    }
  }, [searchParams])

  const updateQuery = useCallback(
    (updateData: Partial<BasicRequestList & { using?: string }>) => {
      const newQuery: any = _pickBy(
        {
          ...query,
          ...updateData
        },
        _identity()
      )
      navigate({
        pathname: location.pathname,
        search: `?${createSearchParams(newQuery)}`,
      });
    },
    [location.pathname, navigate, query],
  )

  const resetFilter = useCallback(() => {
    const newQuery = {
      page: "1",
      limit: "10"
    }
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(newQuery)}`,
    });
  }, [])

  return (
    <QueryRequestContext.Provider value={{
      query,
      updateQuery,
      resetFilter,
      actualCategory
    }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest };
export default QueryRequestProvider
