import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

type Props = any

dayjs.extend(utc);
dayjs.extend(timezone);

const DatePickerCustom = ({ onChange, value, fieldKey, form, customLabel, ...rest }: Props) => {
    const [searchParams] = useSearchParams()

    const handleChangeValue = (value: dayjs.Dayjs) => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        const utc = value?.tz(tz).format('YYYY-MM-DD')
        onChange(utc)
    }

    useEffect(() => {
        if (!fieldKey) return
        const searchValues = Object.fromEntries(searchParams.entries())
        form?.setFieldValue(fieldKey, searchValues[fieldKey])
    }, [fieldKey, searchParams])

    return (
        <DatePicker
            {...rest}
            placeholder='Select date'
            style={{ width: '100%' }}
            onChange={handleChangeValue}
            value={!!value ? dayjs(value) : undefined}
        />
    )
}

export default DatePickerCustom