import { AiOutlineUpload } from 'react-icons/ai'
import { Input } from 'antd'

import './styles.css'

type Props = {
    onChange?: (e: any) => void
    value?: any
    accept?: string
}
const UploadFile = ({ onChange, value, accept = '*' }: Props) => {

    const handleOnChangeInput = (e: any) => {
        if (onChange) {
            onChange(e.target.files[0])
        }
    }

    return (
        <div>
            <label htmlFor='templates-upload' className='group'>
                <span>
                    <AiOutlineUpload />
                </span>
                <span>
                    Choose a file to upload
                </span>
            </label>
            {value?.name &&
                <div><span style={{ fontWeight: 500 }}>File name:</span> {value?.name}</div>
            }
            <Input id='templates-upload' type='file' onChange={handleOnChangeInput} className='d-none' accept={accept} />
        </div>
    )
}

export default UploadFile