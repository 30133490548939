import { isArray } from 'lodash';

type SortArray = (array: any[], key?: string | string[]) => any[];

export const sortArray: SortArray = (array: any[], key = 'label'): any[] => {
    if (isArray(array)) {
        const newArr = array.sort((a: any, b: any) => {
            if (typeof key === 'string') {
                key = [key];
            }

            let valueA = a;
            let valueB = b;

            for (const k of key) {
                valueA = valueA[k];
                valueB = valueB[k];
            }

            if (!valueA) return 1;
            if (!valueB) return -1;

            if (typeof valueA !== 'string' || typeof valueB !== 'string') return -1;

            const labelA = valueA.toUpperCase().trim();
            const labelB = valueB.toUpperCase().trim();

            return labelA.localeCompare(labelB);
        });

        return newArr;
    } else {
        return [];
    }
};
