import { Card, Empty, Image } from 'antd';
import dayjs from 'dayjs';
import { BasicTemplate, Design } from 'modules/template/apis/interfaces/templates';
import Copy from 'modules/template/components/copy-clip-board';
import OverlayImage from "modules/template/components/overlay-img";

type Props = {
    data?: BasicTemplate
    fetching?: boolean
    loading?: boolean
}

const formatDate = (date?: string) => {
    if (!date) return '--'

    return dayjs(date).format('DD/MM/YYYY HH:mm:ss')
}

const DesignInformation = ({ data, fetching }: Props) => {
    const { design = {} } = data || {}
    const {
        design_name = '--',
        sku = '--',
        updated_at,
        created_at,
        design_url,
    } = design as Design || {}


    return (
        <Card
            size='small'
            title="Design Information"
            loading={fetching}
            className='h-100'
            styles={{
                body: {
                    height: '100%'
                }
            }}
        >
            <div className='d-flex gap-4'>
                <Card size='small' style={{ width: '100%' }}>
                    <div>
                        <p className='d-flex justify-content-between'>
                            <span style={{ fontWeight: 500 }}>Name:</span>
                            <span className='text-end'>
                                <Copy text={design_name}>
                                    {design_name}
                                </Copy>
                            </span>
                        </p>
                        <p className='d-flex justify-content-between'>
                            <span style={{ fontWeight: 500 }}>SKU:</span>
                            <span className='text-end'>
                                <Copy text={sku}>
                                    {sku}
                                </Copy>
                            </span>
                        </p>
                        <p className='d-flex justify-content-between'>
                            <span style={{ fontWeight: 500 }}>Updated At:</span>
                            <span>{formatDate(updated_at)}</span>
                        </p>
                        <p className='d-flex justify-content-between'>
                            <span style={{ fontWeight: 500 }}>Created At:</span>
                            <span>{formatDate(created_at)}</span>
                        </p>
                    </div>
                </Card>
                <Card size='small'>
                    <div style={{ width: 300 }}>
                        {design_url ?
                            <OverlayImage
                                originLink={design_url}
                                isZoom={false}
                                iconSize='middle'
                                iconClose={false}
                            >
                                <Image
                                    src={design_url}
                                    width={280}
                                    height={280}
                                    style={{ objectFit: 'contain' }}
                                />
                            </OverlayImage>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No image" />
                        }
                    </div>
                </Card>
            </div>
        </Card>
    )
}

export default DesignInformation