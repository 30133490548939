
import { useQuery } from 'react-query';

import getDataSelectFilter from './fetch';
import { DataSelectFilterRequest } from './interfaces';

type UseQueryGetDataSelectFilterRequest = {
    enabled?: boolean;
    fieldsName?: {
        value: string
        label: string
    }
} & DataSelectFilterRequest;

export function useQueryGetDataSelectFilter(params?: UseQueryGetDataSelectFilterRequest) {
    const { enabled = true, fieldsName = { value: 'value', label: 'label' }, ...rest } = params || {}

    const DATA_SELECT_FILTER = rest?.url || ''

    return useQuery({
        queryKey: [DATA_SELECT_FILTER, rest],
        queryFn: () => getDataSelectFilter(rest),
        select: (res) => {
            const { value, label } = fieldsName

            return {
                ...res,
                data: res?.data?.map((i: any) => ({
                    ...i,
                    value: i?.[value]?.toString(),
                    label: i?.[label]
                }))
            }
        },
        refetchOnMount: false,
        enabled
    });
}

export * from './interfaces';
