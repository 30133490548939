import { useQuery } from 'react-query';
import getRedirectLoginMs, { TypeRedirectLoginMs } from 'apis/auth/redirect-login-ms';
import { QUERY_KEYS } from 'constant/query';

const useGetRedirectLoginSocial = ({ enable = true, code }: TypeRedirectLoginMs & { enable?: boolean } = {}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_TOKEN_LOGIN_SOCIAL, code],
        queryFn: () => getRedirectLoginMs({ code }),

        enabled: !!enable
    });
};

export default useGetRedirectLoginSocial;
