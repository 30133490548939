import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Form, Row, Typography } from "antd/lib";
import Input from "antd/lib/input/Input";
import login from "apis/auth/login";
import { LoginPayload } from "apis/auth/login/interface";
import loginMs from "apis/auth/login-ms";
import { CATALOG } from "constant/path";
import { setUser } from "helpers/auth";
import { setUpToken } from "utils/fetcher";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth } from "../contexts/auth-context";

import style from "./style.module.scss"

const Login = () => {
  const { saveAuth, setCurrentUser } = useAuth()

  const navigate = useNavigate();

  const loginMutation = useMutation((payload: LoginPayload) => login(payload))
  const loginMsMutation = useMutation({ mutationFn: () => loginMs() });


  const handleLogin = async (values: any) => {
    try {
      const result: any = await loginMutation.mutateAsync({ email: values.email, password: values.password })
      if (!!result && result.access_token) {
        saveAuth(result)
        setCurrentUser(result?.access_token)
        setUpToken(result?.access_token)
        navigate(CATALOG)
        setUser({ user_id: result?.user_id } as any)
      } else {
        toast.error(result.message)
      }
    } catch (error: any) {
      toast.error(error.message)
    }
    // e.preventDefault();

    // if (!email || !password) return;
    // localStorage.setItem(
    //   "user",
    //   JSON.stringify({
    //     email,
    //     password,
    //   })
    // );
    // setTimeout(() => {
    //   navigate("/redirect");
    // }, 500);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleMessage = (event: MessageEvent) => {
    if (event.origin === window.location.origin && event.data?.access_token) {
      window.location.reload();

    }
  };

  const handleLoginMicrosoft = async (values: any) => {
    const result: any = await loginMsMutation.mutateAsync()
    if (!!result) {
      window.open(result?.url, 'Microsoft Login', 'width=600,height=400');
    }
  };

  return (
    <div
      className={`${style.login}`}
      style={{
        backgroundImage: `url('/media/bg-login.jpg')`,
      }}
    >
      <Row className={style.main}>
        <Card
          hoverable
          className={style.card}
          actions={[
            <Typography.Link
              className={style.signMicrosoft}
              onClick={handleLoginMicrosoft}
            >

              <img
                src={"microsoft_logo.png"}
                height={"16"}
                className="pl-2"
                alt="sign with microsoft"
              />Sign in with Microsoft
            </Typography.Link>]}
          title={
            <Typography.Title
              style={{
                color: "#fff",
              }}
              level={3}
            >
              {process.env.REACT_APP_TITLE_SIGN_IN}
            </Typography.Title>
          }
          bordered={false}
        >

          <div className={style.loginLogo}>
            <img
              src={process.env.REACT_APP_LOGO_URL}
              alt="Logo"
              className={style.loginLogoImg}
            />
          </div>
          <div>
            <Form className={style.loginFormGroup} onFinish={handleLogin} disabled={loginMutation.isLoading}  >
              <Form.Item
                name="email"
                rules={[{ required: true, whitespace: true, message: 'Please input your Email!' }]}>
                <Input
                  size="large"
                  addonBefore={
                    <div className={style.iconAddon}>
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  }
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item name={"password"} rules={[{ required: true, whitespace: true, message: 'Please input your Password!' }]}>
                <Input
                  size="large"
                  addonBefore={<FontAwesomeIcon icon={faKey} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item className="d-flex justify-content-end">
                <Button loading={loginMutation.isLoading} size="large" className="ml-auto" htmlType="submit" type="primary">
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default Login;
