import { Card, Empty, Image } from 'antd'
import { isEmpty } from 'lodash'
import { BasicTemplate } from 'modules/template/apis/interfaces/templates'

import MappingReview from './mapping-review'

type Props = {
    data?: BasicTemplate
    fetching?: boolean
    loading?: boolean
}

const TemplateListImage = ({ data, fetching, loading }: Props) => {
    const { mockups = [] } = data || {}

    return (
        <Card
            size='small'
            title="Mockups"
            loading={fetching}
            extra={!loading && <MappingReview data={data} />}
        >
            <div className={`d-flex gap-2 flex-wrap ${isEmpty(mockups) ? 'justify-content-center' : ''}`}>
                {
                    isEmpty(mockups) ?
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        :
                        mockups?.map((i: string) => {
                            return (
                                <div>
                                    <Image
                                        src={i}
                                        width={200}
                                    />
                                </div>
                            )
                        })
                }
            </div>
        </Card>
    )
}

export default TemplateListImage