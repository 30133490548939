import { InputProps } from "antd";

import SelectCheckbox, { SelectCheckboxProps } from "../components/select-checkbox";
import SelectCustom, { SelectCustomProps } from "../components/select-custom";
import CompactFields, { CompactFieldsProps } from "./components/compact";
import DatePickerCustom from "./components/date-picker";
import InputFilter from "./components/input-filter";

export enum TypeKeyFormItem {
    Input = "input",
    Select = "select",
    Textarea = "textarea",
    Switch = 'switch',
    Compact = 'compact',
    SelectCheckbox = 'selectCheckbox',
    DatePicker = 'datePicker',
}

export const formFieldsFilter = [
    {
        key: [TypeKeyFormItem.Input],
        component: (props?: InputProps) => <InputFilter {...props} />
    },
    {
        key: [TypeKeyFormItem.Select],
        component: (props?: SelectCustomProps) => <SelectCustom {...props} />,
    },
    {
        key: [TypeKeyFormItem.SelectCheckbox],
        component: (props?: SelectCheckboxProps) => <SelectCheckbox {...props} />,
    },
    {
        key: [TypeKeyFormItem.DatePicker],
        component: (props?: any) => <DatePickerCustom {...props} />,
    },
    {
        key: [TypeKeyFormItem.Compact],
        component: (props?: CompactFieldsProps) => <CompactFields {...props} />,
    }
]