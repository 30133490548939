import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import useGetRedirectLoginSocial from 'data/hooks/use-get-redirect-login-social';
import { setUser } from 'helpers/auth';

import { useAuth } from '../contexts/auth-context';

export type TypeLoginMs = {
    access_token?: string
    token_type?: string
    expires_in?: number
    user_id?: any
}

const WaitingAuthMicrosoft = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const code = window.location.search.split('code=')[1]
    const fetchTokenLoginSocial = useGetRedirectLoginSocial({ code })
    const { saveAuth } = useAuth();
    console.log("callback", code);

    useEffect(() => {
        // window.opener.postMessage(result, window.location.origin)
        // window.close()
        console.log("fetchTokenLoginSocial", fetchTokenLoginSocial);
        try {
            const result = fetchTokenLoginSocial?.data as TypeLoginMs || {} as TypeLoginMs
            if (!!result && result.access_token) {
                saveAuth(result)
                // setCurrentUser(result?.access_token)
                // setUpToken(result?.access_token)

                // const previousRedirect = getPreviousUrl()
                // navigate(previousRedirect)
                setUser({ user_id: result?.user_id } as any)
                window.opener.postMessage(result, window.location.origin)
                // window.open(process.env.REACT_APP_PUBLIC_URL + "dashboard", '_blank')
                window.close();
            } else {
                saveAuth(null)
                toast.error("Login failed")
                // window.close();

            }
        } catch (error: any) {
            console.log(error?.message || 'Login failed!');
        }
    }, [fetchTokenLoginSocial?.data, fetchTokenLoginSocial.dataUpdatedAt, saveAuth])

    return (
        <div className='w-100 vh-100 d-flex justify-content-center align-items-center gap-2'>
            <Spin />
            <div style={{ fontWeight: 500 }}>Signing in to Microsoft...</div>
        </div>
    )
}

export default WaitingAuthMicrosoft