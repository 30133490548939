import { useEffect, useMemo } from 'react'
import { FormInstance } from 'antd'
import { isEmpty } from 'lodash'

import { useFilterContext } from '../context/filter-context'
import { FieldsProp } from '../filter'
import { useQueryGetDataSelectFilter } from '../queries/use-query-get-data-select-filter'
import { TypeKeyFormItem } from './fields'
import FilterFormItem from './form-item'


type Props = {
    value?: any
    onChange?: (value: any) => void
    field: FieldsProp
    form: FormInstance
}

const FilterField = (props: Props) => {
    const { field, onChange, value, form } = props
    const { onSetSelectOptions } = useFilterContext()

    const fetchOptionData = useQueryGetDataSelectFilter({
        enabled: !isEmpty(field?.url) && [TypeKeyFormItem.Select, TypeKeyFormItem.SelectCheckbox].includes(field?.type),
        url: field?.url || '',
        minimize: true,
        fieldsName: field?.fieldsName,
        ...field?.queryFieldParams
    })

    const options = useMemo(() => {
        const newOptions = fetchOptionData?.data?.data || []

        return newOptions
    }, [fetchOptionData?.data?.data, fetchOptionData.isLoading])

    useEffect(() => {
        onSetSelectOptions({
            field,
            options: options,
            loading: fetchOptionData.isLoading
        })
    }, [options, fetchOptionData.isLoading])

    return (
        <FilterFormItem
            field={field}
            options={options}
            form={form}
            value={value}
            onChange={onChange}
            loading={fetchOptionData.isLoading}
        />
    )
}

export default FilterField