import { useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Col, Form, Modal, Row } from 'antd';
import { QUERY_KEYS } from 'constant/query';
import { notify } from 'modules/catalog/components/notify-component';
import createUploadMockup, { CreateUploadMockup } from 'modules/template/apis/create-upload-mockup';
import { BasicTemplate } from 'modules/template/apis/interfaces/templates';
import UploadFile from 'modules/template/list/modal-template/upload';

type Props = {
    data?: BasicTemplate
}

const ModalUpload = ({ data }: Props) => {
    const queryClient = useQueryClient()
    const [form] = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const mutationCreate = useMutation({ mutationFn: (params: CreateUploadMockup) => createUploadMockup(params) })

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (values: any) => {
        const formData = new FormData()
        formData.append('mockup_file', values.mockup_file)
        const result = await mutationCreate.mutateAsync({
            id: data?.id,
            formData: formData
        })
        notify(result, 'Upload')
        if (result?.success) {
            queryClient.invalidateQueries([QUERY_KEYS.GET_TEMPLATE_DETAIL])
            handleCancel()
        }
    }

    const handleAfterClose = () => {
        form.resetFields()
    }

    return (
        <>
            <Button
                type="primary"
                onClick={showModal}
                icon={<FaUpload />}
                size='small'
                ghost
            />
            <Modal
                title="Upload File"
                open={isModalOpen}
                onOk={form.submit}
                onCancel={handleCancel}
                afterClose={handleAfterClose}
                confirmLoading={mutationCreate.isLoading}
            >
                <Form
                    labelAlign="left"
                    layout='vertical'
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Choose Mockup File"
                                name="mockup_file"
                                className='mb-0'
                                rules={[{ required: true, message: 'Mockup file is required!' }]}
                            >
                                <UploadFile accept='.psd' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalUpload