import { UserModel } from 'interfaces/auth';

const AUTH_LOCAL_STORAGE_KEY = 'admin-cnw';
const USER_LOCAL_STORAGE_KEY = 'user';
const getAuth = (): any => {
    if (!localStorage) {
        return;
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return;
    }

    try {
        const auth: any = JSON.parse(lsValue) as any;
        if (auth) {
            // You can easily check auth_token expiration also
            return auth;
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
    }
};

const setAuth = (auth: any) => {
    if (!localStorage) {
        return;
    }

    try {
        const lsValue = JSON.stringify(auth);
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
    }
};

const removeAuth = () => {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
    }
};

const getUser = (): UserModel | undefined => {
    if (!localStorage) {
        return;
    }

    const lsValue: string | null = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return;
    }

    try {
        const user: any = JSON.parse(lsValue) as any;
        if (user) {
            return user;
        }
    } catch (error) {
        console.error('USER LOCAL STORAGE PARSE ERROR', error);
    }
};

const setUser = (user: UserModel) => {
    if (!localStorage) {
        return;
    }

    try {
        const lsValue = JSON.stringify(user);
        localStorage.setItem(USER_LOCAL_STORAGE_KEY, lsValue);
    } catch (error) {
        console.error('USER LOCAL STORAGE SAVE ERROR', error);
    }
};

const removeUser = () => {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error('USER LOCAL STORAGE REMOVE ERROR', error);
    }
};

export { AUTH_LOCAL_STORAGE_KEY, getAuth, getUser, removeAuth, removeUser, setAuth, setUser };
