import { useState } from 'react';
import { MdDelete } from "react-icons/md";
import { Button, Popover } from 'antd';
import { useFilterContext } from 'components/filter/context/filter-context';
import { FieldsProp } from 'components/filter/filter';

type Props = {
    field: FieldsProp
}

const DeleteCustomField = ({ field }: Props) => {
    const { filterSettings, useSetLocalSettings, onSetFilterSettings } = useFilterContext();
    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const handleDelete = () => {
        const newFields = filterSettings.fields.filter((f) => f.id !== field.id);
        const contextSettingValues = {
            ...filterSettings,
            fields: newFields
        }
        onSetFilterSettings(contextSettingValues);
        useSetLocalSettings(contextSettingValues);
        hide();
    }

    if (!field?.isCustom) return null

    return (
        <Popover
            content={
                <>
                    <div>
                        Are you sure you want to delete this field?
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: 8, marginTop: 8 }}>
                        <Button onClick={hide} size='small'>Cancel</Button>
                        <Button size='small' danger type='primary' onClick={handleDelete}>Delete</Button>
                    </div>
                </>
            }
            title="Confirm"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            autoAdjustOverflow={false}
            getPopupContainer={(triggerNode) => triggerNode?.parentElement as any}
            placement='bottomRight'
        >
            <span style={{ cursor: 'pointer' }}>
                <MdDelete color='red' />
            </span>
        </Popover>
    )
}

export default DeleteCustomField