import Filter from 'components/filter';
import useGetActualCategoryList from 'data/hooks/catalog/use-get-actual-category';

import TableComponent from '../components/table-component';
import { useQueryRequest } from '../core/query-request-provider';
import { useTemplatesContext } from '../core/templates-provider';
import useGetTemplateList from '../data/hooks/use-get-template-list';
import Header from '../header';
import { columns } from './column';
import { filterFields } from './filter-fields';
import ModalTemplate from './modal-template';

const TemplatesList = () => {
    const value = useTemplatesContext();
    const { query, updateQuery, actualCategory = [] } = useQueryRequest();
    const fetchList = useGetTemplateList({ ...query, actualCategory: actualCategory })
    const data = fetchList?.data || {};

    const { page, limit, sort, ...other } = query;


    const arr: any = [];
    value.row?.selectedRows.forEach((item: any) => {
        arr.push(item.id);
    });

    return (
        <div>
            <Header title="Templates" />
            <div className='mb-2 text-end'>
                <ModalTemplate />
            </div>
            <Filter
                fields={filterFields}
                col={4}
            />
            <TableComponent
                isLoading={fetchList?.isFetching}
                data={data || {}}
                columns={columns()}
                updateQueryTable={updateQuery}
                tableLoading={fetchList?.isFetching}
            />
        </div>
    )
}

export default TemplatesList