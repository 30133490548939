import { Input, InputProps } from "antd";
import SelectCustom, { SelectCustomProps } from "components/filter/components/select-custom";



export enum TypeCompactFields {
    Input = "input",
    Select = "select",
}

export const compactFields = [
    {
        key: [TypeCompactFields.Input],
        component: ({ inputType, ...rest }: { inputType?: string } & InputProps) => <Input {...rest} type={inputType} />
    },
    {
        key: [TypeCompactFields.Select],
        component: (props: SelectCustomProps) => <SelectCustom {...props} />,
    },
]