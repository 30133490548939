
import { Select, SelectProps } from 'antd'

export type SelectCustomProps = SelectProps & { customLabel?: any }

const convertNormalizeString = (value: string) => {
    if (!value) return value;
    let stringValue = value;
    if (typeof stringValue === 'number') {
        const numberValue = stringValue as number;
        stringValue = numberValue.toString();
    } else if (stringValue === undefined) {
        stringValue = '';
    }

    const newString = stringValue?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

    return newString;
};


const SelectCustom = ({ fieldNames, customLabel, ...props }: SelectCustomProps) => {
    return (
        <Select
            fieldNames={{
                ...fieldNames
            }}
            filterOption={(input: any, option: any) => {
                const label = option[fieldNames?.label || "label"]
                const oriString = convertNormalizeString(label)
                const stringToCompare = convertNormalizeString(input)
                const result = (`${oriString ?? ''}`?.toLowerCase() ?? '').indexOf(stringToCompare?.toLowerCase()) > -1;

                return result
            }}
            showSearch
            {...props}
        />
    )
}

export default SelectCustom