import { FetcherResponse } from '../../../interfaces/fetcher';
import { axiosInstance } from '../../../utils/fetcher';
import { ActualCategoryListRequest, ActualCategoryResponse } from './interface';

const getActualCategoryList = async (params: ActualCategoryListRequest): Promise<ActualCategoryResponse[]> => {
    const { data } = await axiosInstance.get<FetcherResponse<ActualCategoryResponse[]>>('api/v1/actual-categories', {
        params
    });

    return data.data || [];
};

export default getActualCategoryList;
