import { useContext } from 'react'
import { ConfirmDialogContext } from 'contexts/confirm-dialog.context'


const useConfirmDialog = () => {
  const confirmDialogData = useContext(ConfirmDialogContext)

  return confirmDialogData
}

export default useConfirmDialog
