const LoadingImage = () => {
    return (
        <div className="container">
            <div className="loader"><span></span></div>
            <div className="loader"><span></span></div>
            <div className="loader"><i></i></div>
            <div className="loader"><i></i></div>
        </div>
    )
}

export default LoadingImage