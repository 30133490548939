import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoadingComponent from 'components/common/loading-component'
import { ConfirmDialogProvider } from 'contexts/confirm-dialog.context'
import { PageDataProvider } from 'contexts/page-data'
import Error404 from 'modules/errors/components/error-404'
import Templates from 'modules/template'

import Layout from '../layout/master-layout'


const Store = lazy(() => import('../modules/store'))
const Catalog = lazy(() => import('../modules/catalog'))
const DownloadCenter = lazy(() => import('../modules/download-center'))
const Labeling = lazy(() => import('../modules/labeling'))

const Report = lazy(() => import('../modules/reports'))
const Carrier = lazy(() => import('../modules/carriers'))
const Designs = lazy(() => import('../modules/designs'))
const Keywords = lazy(() => import('../modules/keywords'))

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path='*' element={<Navigate to='/404' />} />
            <Route element={
                <ConfirmDialogProvider >
                    <PageDataProvider >
                        <Layout />
                    </PageDataProvider>
                </ConfirmDialogProvider>

            } >
                <Route path='login/*' element={<Navigate to='/catalog' />} />
                {/* <Route path='profile' element={<Profile />} /> */}
                <Route path='store/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <Store />
                    </Suspense>
                } />
                <Route path='catalog/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <Catalog />
                    </Suspense>
                } />
                <Route path='labeling/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <Labeling />
                    </Suspense>
                } />
                <Route path='reports/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <Report />
                    </Suspense>
                } />
                <Route path='download-center/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <DownloadCenter />
                    </Suspense>
                } />
                <Route path='designs/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <Designs />
                    </Suspense>
                } />
                <Route path='carrier/*' element={<Carrier />} />
                <Route path='templates/*' element={<Templates />} />
                <Route path='keywords/*' element={
                    <Suspense fallback={<LoadingComponent />}>
                        <Keywords />
                    </Suspense>
                } />
            </Route>
            <Route index element={<Navigate to='/catalog' />} />
            <Route path='/404' element={<Error404 />} />
        </Routes>
    )
}

export default PrivateRoutes