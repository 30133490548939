import { useQuery } from 'react-query'
import getUser from 'apis/common/get-a-user';
import { QUERY_KEYS } from 'constant/query'


const useGetUser = (params: any) => {
  return useQuery([QUERY_KEYS.USER, params], () => getUser(params), {
    enabled: !!params
  });
}
export default useGetUser