import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constant/query';
import getListCatalogDesign from 'modules/designs/apis/catalog-design/get-list-catalog-designs';

type UseGetCatalogDesignList = {
    enabled?: boolean;
    actualCategory?: any[];
    colorsList?: any[];
    attributeList?: any[];
    [x: string]: any;
}

const useGetCatalogDesignList = (params: UseGetCatalogDesignList = {}) => {
    const { enabled = true, actualCategory, colorsList = [], attributeList = [], ...rest } = { ...params }

    return useQuery({
        queryKey: [QUERY_KEYS.GET_CATALOG_DESIGN_LIST, rest],
        queryFn: () => getListCatalogDesign(rest),
        select: (res) => {
            return {
                ...res,
                data: res?.data?.map((d) => {
                    return {
                        ...d,
                        category: actualCategory?.find((c) => Number(d?.product_type) === Number(c?.id)),
                        color: colorsList?.find((c) => Number(d?.colors) === Number(c?.id)),
                        attribute_values: d.attribute_values?.map((av: any) => {
                            return {
                                ...av,
                                attribute: attributeList?.find((a: any) => Number(av?.attribute_id) === Number(a?.id))
                            }
                        })
                    };
                }),
            }

        },
        enabled: !!enabled
    })
};

export default useGetCatalogDesignList;
