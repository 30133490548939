import React, { useMemo, useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useFilterContext } from 'components/filter/context/filter-context';
import { isEmpty } from 'lodash';

import Copy from '../../copy-clip-board';
import SelectCustom from '../../select-custom';
import { optionsFields, sampleDataSource, sampleFieldName } from './options';

const ModalAddField: React.FC = () => {
    const [form] = Form.useForm();
    const formValues = useWatch([], form);

    const { filterSettings, useSetLocalSettings, onSetFilterSettings } = useFilterContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const fields = useMemo(() => filterSettings.fields, [filterSettings.fields]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = (values: any) => {
        const { label, name, type, dataSources = [], url, fieldsName } = values;

        const f = {
            id: name,
            label: label,
            key: name,
            type: type,
            dataSources: !isEmpty(dataSources) ? JSON.parse(dataSources) : [],
            url: url,
            fieldsName: !isEmpty(fieldsName) ? JSON.parse(fieldsName) : undefined,
            isCustom: true
        }
        if (fields.findIndex((field) => field.id === f.id) !== -1) return toast.error('Name is already exists!');
        const contextSettingValues = {
            ...filterSettings,
            fields: [...fields, f]
        }
        onSetFilterSettings(contextSettingValues);
        useSetLocalSettings(contextSettingValues);
        toast.success('Field added successfully!');
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                type="primary"
                onClick={showModal}
                size='small'
                ghost
            >
                Add
            </Button>
            <Modal
                title="Modal Add Temporary Field"
                open={isModalOpen}
                onCancel={handleCancel}
                onOk={form.submit}
                afterClose={form.resetFields}
            >
                <Form
                    autoComplete="off"
                    form={form}
                    onFinish={handleOk}
                    layout='vertical'
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                label="Label"
                                name="label"
                                rules={[{ required: true, message: 'Please input label!' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder='Enter label' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input name!' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder='Example: color_id' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{ required: true, message: 'Please input your type!' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <SelectCustom
                                    options={optionsFields}
                                    placeholder="Select type"
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {formValues?.type === 'selectCheckbox' && (
                                <Form.Item
                                    label="Field Type"
                                    name="field_type"
                                    rules={[{ required: true, message: 'Please input field type!' }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <SelectCustom
                                        options={[{ value: 'dataSources', label: 'Data Source' }, { value: 'url', label: 'URL' }]}
                                        placeholder="Select type of field"
                                        allowClear
                                    />
                                </Form.Item>
                            )}
                        </Col>
                        {formValues?.field_type === 'dataSources' && (
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <div>
                                            Data Source
                                            <span style={{ marginInlineStart: 4 }}>
                                                <Copy text={JSON.stringify(sampleDataSource)} title='Copy sample'><FaRegCopy /></Copy>
                                            </span>
                                        </div>
                                    }
                                    name="dataSources"
                                    rules={[
                                        { required: true, message: 'Please input data source!' },
                                        {
                                            validator: (_: any, value: string) => {
                                                try {
                                                    JSON.parse(value);

                                                    return Promise.resolve();
                                                } catch (err) {
                                                    return Promise.reject(new Error('Invalid JSON'));
                                                }
                                            }
                                        }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input.TextArea
                                        placeholder="Example: [{label: 'Label 1', value: 'Value 1'}]"
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {formValues?.field_type === 'url' && (<>
                            <Col span={24}>
                                <Form.Item
                                    label="URL"
                                    name="url"
                                    rules={[{ required: true, message: 'Please input url!' }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input placeholder="Example: api/v1/tags?page=1&limit=-1" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        <div>
                                            Fields Name
                                            <span style={{ marginInlineStart: 4 }}>
                                                <Copy text={JSON.stringify(sampleFieldName)} title='Copy sample'><FaRegCopy /></Copy>
                                            </span>
                                        </div>
                                    }
                                    name="fieldsName"
                                    style={{ marginBottom: 0 }}
                                    rules={[{
                                        validator: (_: any, value: string) => {
                                            if (!value) return Promise.resolve();
                                            try {
                                                JSON.parse(value);

                                                return Promise.resolve();
                                            } catch (err) {
                                                return Promise.reject(new Error('Invalid JSON'));
                                            }
                                        }
                                    }]}
                                >
                                    <Input.TextArea placeholder='Example: {"value": "id", "label": "site_name"}' />
                                </Form.Item>
                            </Col>
                        </>
                        )}
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalAddField;