export const toSnakeCaseString = (str?: string): string => {
    if (!str) return '';

    return str.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const snakeToTitleCase = (str?: string) => {
    if (!str) return '';

    return str
        .replace(/_([a-z])/g, function (match, p1) {
            return ' ' + p1.toUpperCase();
        })
        .replace(/^\w/, function (firstChar) {
            return firstChar.toUpperCase();
        });
};
