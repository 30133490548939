export const QUERY_KEYS = {
    CARRIERS: 'carriers',
    USER: 'user',
    CARRIER_DETAIL: 'carrier-detail',
    CATALOGS: 'catalogs',
    STORES: 'stores',
    STORE_DETAIL: 'store-detail',
    ACTUAL_CATEGORY: 'actual-category',
    ACTUAL_CATEGORY_DETAIL: 'actual-category-detail',
    CUSTOM_LABEL_0: 'custom_label_0',
    CUSTOM_LABEL_1: 'custom_label_1',
    CUSTOM_LABEL_4: 'custom_label_4',
    IMPORT_REQUEST: 'import_request',
    STATUS_AMZ: 'status_amz',
    TRADE_MARK: 'trade_mark',
    DESIGN_BY: 'design_by',
    STATUS_REQUEST_MOCKUP: 'status_request_mockup',
    MBAs: 'mbas',
    STORE: 'store',
    COLOR: 'color',
    COLOR_v2: 'color_v2',
    ERROR: 'error',
    DETAIL_CATALOG: 'detail_catalog',
    LIST_STORE: 'list-store',
    COMPILE_NAME_HEADER: 'compile_name_header',
    COMPILE_NAME_FOOTER: 'compile_name_footer',
    PLAT_FROM: 'platform',
    GROUP_PRICE: 'group-price',
    VENDOR: 'vendor',
    PRODUCT_TYPE: 'product-type',
    META_TITLE: 'meta-title',
    DEPLOY_CONTENT: 'deploy-content',
    DETAIL_DEPLOY_CONTENT: 'detail-deploy-content',
    DEPLOY_CATEGORY: 'deploy-category',
    DEPLOY_CATEGORY_MAGENTO: 'deploy-category-magento',
    DETAIL_META_TITLE: 'detail-meta-title',
    GET_STORES_BY_PLATFORM: 'get-store-by-platform',
    HISTORY_CUSTOM_LABEL: 'get-history-custom-label',
    GET_COMPILE_NAME: 'get-compile-name',
    DEPLOY_EDIT_REPLACE_CHARACTER: 'deploy-edit-replace-character',
    DEPLOY_CATALOG_LIST: 'deploy-catalog-list',
    GROUP_STORE_LIST: 'group-store-list',
    GROUP_SITE_LIST: 'group-site-list',
    DEPARTMENT_LIST: 'department-list',
    DETAIL_CATALOG_CONTENT: 'detail-catalog-content',
    PRICING_TABLE_LIST: 'pricing-table-list',
    DETAIL_PRICING_TABLE: 'detail-pricing-table',
    CATALOG_CONTENT_LIST: 'catalog-content-list',
    META_TITLE__LIST: 'meta-title-list',
    DETAIL_META_TITLE_V2: 'detail-meta-title-v2',
    VENDOR_TITLE_LIST: 'vendor-title-list',
    DETAIL_VENDOR_TITLE: 'detail-vendor-title',
    PREDICT_CATEGORIES_LIST: 'predict-categories-list',
    OPTION_SET_LIST: 'option_set_list',
    REQUEST_HISTORY_LIST: 'request-history-list',
    REQUEST_HISTORY_STATUS: 'request-history-status',
    ALL_USER: 'ALL_USER',
    PAGE_TEMPLATES_LIST: 'page-templates-list',
    TYPE_PAGE_TEMPLATES_LIST: 'type-page-templates-list',
    PAGE_TEMPLATES_DETAIL: 'page-templates-detail',
    TYPE_PAGE_TEMPLATES_LIST_V2: 'type-page-templates-list-v2',

    KEYWORDS_MANAGE_LIST: 'keywords-manage-list',
    KEYWORDS_MANAGE_LIST_V2: 'keywords-manage-list-v2',
    GROUP_KEYWORDS_MANAGE_LIST: 'keywords-manage-list',
    REPLACE_CHARACTERS_LIST: 'replace-characters-list',
    REPLACE_CHARACTERS_DETAIL: 'replace-characters-detail',

    CUSTOM_LABELS_LIST: 'custom-labels-list',
    MAX_PRIORITY_CUSTOM_LABELS: 'max-priority-custom-labels',
    DETAIL_CUSTOM_LABELS: 'detail-custom-labels',
    LOG_CUSTOM_LABELS: 'log-custom-labels',
    MAX_PRIORITY_REPLACE_CHARACTERS: 'max-priority-replace-characters',
    BUSINESS_UNIT: 'page-templates-list',
    BELONG_ACCOUNT: 'belong-account',
    CATALOG_DEPLOY_LIST: 'catalog-deploy-list',
    CATALOG_DEPLOY_DETAIL: 'catalog-deploy-detail',
    GET_PATH_CONFIG_SITE_TEMPLATE: 'get-path-config-site-template',
    GET_EXPORT_PRICING_TABLE: 'get-export-pricing-table',
    GET_PATH_CONFIG_SITE_TEMPLATE_DETAIL: 'get-path-config-site-template-detail',
    GET_LIST_PRODUCT_FEED: 'get-list-product-feed',
    GET_LIST_DOWNLOAD_CENTER: 'get-list-download-center',

    GET_FILTER_DOWNLOAD_CENTER_LIST: 'get-filter-download-center-list',
    GET_LOG_DOWNLOAD_CENTER_LIST: 'get-log-download-center-list',

    GET_TYPE_DOWNLOAD_CENTER_LIST: 'get-type-download-center-list',
    GET_CMS_PAGE: 'get-cms-page',

    PAYMENT_METHOD: 'payment-method',
    PRIVATE_POLICY: 'private-policy',
    SHIPPING_COST_AND_DELIVERY: 'shipping-cost-and-delivery',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    CONTACT_US: 'contact-us',
    FAQS: 'faqs',
    ABOUT_US: 'about-us',
    ORDER_TRACKING: 'order-tracking',
    RETURN_AND_REFUND_POLICY: 'return-and-refund-policy',
    REFUND_POLICY: 'refund-policy',
    RETURN_POLICY: 'return-policy',
    CANCELLATION_POLICY: 'cancellation-policy',
    DMCA_NOTICE: 'dmca-notice',
    DEFAULT: 'default',
    GET_CMS_PAGE_MAGENTO: 'get-cms-page-magento',
    GET_CMS_BLOCK_MAGENTO: 'get-cms-block-magento',

    GET_STATUS_REVIEW_CL: 'get-status-review-cl',
    GET_CONTENT_PREVIEW_PAGE_TEMPLATE: 'get-content-preview-page-template',
    SIGN_MS: 'SIGN_MS',
    GET_TOKEN_LOGIN_SOCIAL: 'get-token-login-social',
    GET_CATALOG_DESIGN_LIST: 'get-catalog-design-list',
    MOCKUP_TEMPLATE: 'mockup-template',
    GET_TEMPLATE_LIST: 'get-template-list',
    GET_TEMPLATE_DETAIL: 'get-template-detail',
    GET_KEYWORD_LIST: 'get-keyword-list',
    GET_KEYWORD_DETAIL: 'get-keyword-detail',
    GET_LAYER_LIST: 'get-layer-detail',
    GET_LOG_DEPLOY_DESIGN: 'get-log-deploy-design',
    GET_CATALOG_DESIGN_DETAIL: 'get-catalog-design-detail',
    GET_LIST_ATT_CATALOG_DESIGN: 'get-list-att-catalog-design'
};

export const QUERY_KEYS_REPORT = {
    REPORT_REQUEST_DEPLOY: 'report-request-deploy',
    REPORT_REQUEST_CATALOG_DESIGN_DEPLOY: 'report-request-catalog-design-deploy',
    STATUS_REPORT: 'status-report',
    TYPE_REPORT: 'type-report',
    LIMIT_WARNING: 'limit-warning',
    CREATED_BY: 'created-by',
    REQUEST_INFO: 'request-info',
    CONDITION: 'condition',
    HISTORY: 'history'
};
