import { axiosInstance } from 'utils/fetcher'

export type UpdateTemplatesParams = {
    id?: any,
    name?: string,
    mockup_file?: any,
    category_id?: any,
    number_mockups?: number,
    enable?: string,
    layers?: any
    title?: string
}

const updateTemplates = async (params: UpdateTemplatesParams): Promise<any> => {
    const { id, ...rest } = params || {}
    const data = await axiosInstance.put(`api/v1/catalog-mockup-templates/${id}`, rest)

    return data.data
}

export default updateTemplates