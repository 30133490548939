import { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from 'antd'

type Props = {
    text?: any
    isShowTitle?: boolean
    children?: any
    title?: string
}
const Copy = ({ text, children, title, isShowTitle = true }: Props) => {
    const [titleCopy, setTitleCopy] = useState<string>(title || 'Copy text')

    const handleMouseLeave = () => {
        setTimeout(() => {
            setTitleCopy(title || 'Copy text')
        }, 200)
    }

    if (!text) return (<>{children}</>)

    return (
        <CopyToClipboard
            text={text + ''}
            onCopy={() => {
                setTitleCopy(title ? title.replace('Copy', 'Copied') + '!' : 'Copied!')
            }}
        >
            <span style={{ cursor: 'pointer' }} onMouseLeave={handleMouseLeave}>
                <Tooltip placement="top" title={isShowTitle && titleCopy}>
                    {children}
                </Tooltip>
            </span>
        </CopyToClipboard>
    )
}

export default Copy