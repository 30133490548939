import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Input } from 'antd'

const InputFilter = ({ form, fieldKey, customLabel, ...rest }: any) => {
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (!fieldKey) return
        const searchValues = Object.fromEntries(searchParams.entries())
        form?.setFieldValue(fieldKey, searchValues?.[fieldKey])
    }, [fieldKey, form, searchParams])

    return (
        <Input {...rest} />
    )
}

export default InputFilter