import { useParams } from 'react-router-dom'
import { Col, Row } from 'antd'
import BackButton from 'components/back-button'

import useGetDetailTemplateList from '../data/hooks/use-get-detail-template'
import Header from '../header'
import DesignInformation from './design-information'
import TemplateInfomation from './information'
import TemplateListImage from './list-images'


const DetailTemplates = () => {
    const { id } = useParams()
    const fetchDetail = useGetDetailTemplateList({ id: id })
    const data = fetchDetail?.data?.data
    const {
        name = '',
    } = data || {}

    return (
        <Row gutter={[16, 16]} align="stretch">
            <Col span={24}>
                <Header title={name} />
            </Col>
            <Col span={24}>
                <BackButton href={'/templates/list'} />
            </Col>
            <Col span={6}>
                <TemplateInfomation data={data} fetching={fetchDetail.isFetching} loading={fetchDetail.isLoading} />
            </Col>
            <Col span={10}>
                <DesignInformation data={data} fetching={fetchDetail.isFetching} loading={fetchDetail.isLoading} />
            </Col>
            <Col span={24}>
                <TemplateListImage data={data} fetching={fetchDetail.isFetching} loading={fetchDetail.isLoading} />
            </Col>
        </Row>
    )
}

export default DetailTemplates