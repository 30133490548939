
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormInstance, Select, SelectProps, Skeleton } from 'antd';
import { FieldsProp } from 'components/filter/filter';
import { isEmpty } from 'lodash';

import DropdownRender from './dropdown';
import { TagRender } from './tag-render';

import './styles.css'

export type SelectCheckboxProps =
    { form?: FormInstance, fieldKey?: string, onChange?: any, customLabel?: any } & Omit<SelectProps, 'onChange'>

export enum KeyAllNotSearch {
    AllSearch = 'allSearch',
    ElseSearch = 'elseSearch',
    AllValue = 'ALL',
}
const convertNormalizeString = (value: string) => {
    if (!value) return value;
    let stringValue = value;
    if (typeof stringValue === 'number') {
        const numberValue = stringValue as number;
        stringValue = numberValue.toString();
    } else if (stringValue === undefined) {
        stringValue = '';
    }

    const newString = stringValue?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

    return newString;
};

const customLabelColorCus = (value: any, options?: any[]) => {
    if (isEmpty(options)) return null
    const i = options?.find((o) => o?.id == value)

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
                style={{ width: 16, height: 16, background: i?.code, marginInlineEnd: 4, borderRadius: 4 }}
            />
            <span>{i?.name}</span>
        </div>
    )
}


const labelRender = (selectProps: any, fn: FieldsProp['customLabel']) => {
    try {
        if (!fn) return selectProps?.value

        return fn(selectProps?.value as string, selectProps?.options)
    } catch (error) {
        return null
    }
}



const SelectCheckbox = ({ options, onChange, value, fieldKey, loading, customLabel, ...props }: SelectCheckboxProps) => {
    const ALL = KeyAllNotSearch.AllSearch
    const ELSE = KeyAllNotSearch.ElseSearch
    const [checkedAll, setCheckedAll] = useState<boolean>()
    const [checkedNot, setCheckedNot] = useState<boolean>()
    const [selectedValue, setSelectedValue] = useState<any[]>([])

    const [searchParams] = useSearchParams()

    const searchValues = useMemo(() => {
        return Object.fromEntries(searchParams.entries())
    }, [searchParams])

    const onChangeValues = (
        { values, checkedAll, checkedNot }: { values: any[], checkedAll?: boolean, checkedNot?: boolean }
    ) => {
        setSelectedValue(values)
        onChange([...values, checkedAll ? ALL : '', checkedNot ? ELSE : ''].filter((item) => !!item))
    }

    const handleCheckAll = (event: any) => {
        const checkedValue = event.target.checked
        setCheckedAll(checkedValue)
        if (checkedValue) {
            const values = options?.map((option: any) => option?.value) || []
            onChangeValues({ values, checkedAll: checkedValue, checkedNot })
        } else {
            onChangeValues({ values: [], checkedAll: checkedValue, checkedNot })
        }
    }

    const handleCheckNot = (event: any) => {
        const checkedValue = event.target.checked
        setCheckedNot(checkedValue)
        onChange([...selectedValue, checkedAll ? ALL : '', checkedValue ? ELSE : ''].filter((item) => !!item))
    }

    const handleChangeSelect = (valueSelect: any) => {
        onChangeValues({ values: valueSelect, checkedAll: valueSelect?.length === options?.length, checkedNot })
    }

    useEffect(() => {
        setCheckedAll(!isEmpty(options) && selectedValue?.length === options?.length)
    }, [selectedValue, options])

    useEffect(() => {
        setCheckedNot(value?.includes(ELSE))
        setSelectedValue(value?.filter((item: any) => item !== ALL && item !== ELSE) || [])
    }, [value])

    useEffect(() => {
        if (!fieldKey) return
        const initCheckedAll = searchValues?.[ALL]?.split(',')?.includes(fieldKey as string)
        const initCheckedNot = searchValues?.[ELSE]?.split(',')?.includes(fieldKey as string)

        const values = initCheckedAll ?
            options?.map((option) => option?.value) || []
            : searchValues?.[fieldKey as string]?.split(',')?.filter((item) => item).map((item) => {
                const { value, label } = options?.find((option) => option?.value == item) || {}

                return {
                    value: value ?? item,
                    label: label ?? item
                }
            }) || []

        onChangeValues({
            values,
            checkedAll: initCheckedAll,
            checkedNot: initCheckedNot
        })
    }, [fieldKey, options, searchValues])

    const renderLabel = (value?: string) => {

        const label = customLabelColorCus?.(value, options)
        if (!label) value

        return label
    }

    return (
        <>
            {loading ?
                <Skeleton.Input rootClassName='root-skeleton' active={true} style={{ width: '100%' }} />
                :
                <Select
                    {...props}
                    filterOption={(input: any, option: any) => {
                        const label = option?.label ?? ''
                        const oriString = convertNormalizeString(label)
                        const stringToCompare = convertNormalizeString(input)
                        const result = (`${oriString ?? ''}`?.toLowerCase() ?? '').indexOf(stringToCompare?.toLowerCase()) > -1;

                        return result
                    }}
                    showSearch
                    dropdownRender={(menu) => (
                        <DropdownRender
                            menu={menu}
                            onCheckAll={handleCheckAll}
                            onCheckNot={handleCheckNot}
                            checkedAll={checkedAll}
                            checkedNot={checkedNot}
                            quantity={options?.length ?? 0}
                        // customLabel={customLabel}
                        />
                    )}
                    options={isEmpty(options) ? undefined : options}
                    value={selectedValue}
                    onChange={handleChangeSelect}
                    maxTagCount={checkedAll ? 0 : 'responsive'}
                    tagRender={
                        (checkedAll || checkedNot) ?
                            (props: any) => <TagRender
                                checkedAll={checkedAll}
                                checkedNot={checkedNot}
                                onCheckAll={handleCheckAll}
                                onCheckNot={handleCheckNot}
                                {...props}
                            />
                            :
                            undefined
                    }
                    mode='multiple'
                    loading={loading}
                    optionLabelProp='label'
                    labelRender={customLabel ? (value) => labelRender({ value: value?.value, options }, customLabel) : undefined}
                    optionRender={customLabel ? (value) => labelRender({ value: value?.value, options }, customLabel) : undefined}
                />
            }
        </>
    )
}

export default SelectCheckbox