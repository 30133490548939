import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constant/query';
import getListTemplates from 'modules/template/apis/get-list-templates';

type UseGetTemplatesList = {
    enabled?: boolean;
    actualCategory?: any[];
    [x: string]: any;
}

const useGetTemplateList = (params: UseGetTemplatesList = {}) => {
    const { enabled = true, actualCategory, ...rest } = { ...params }

    return useQuery({
        queryKey: [QUERY_KEYS.GET_TEMPLATE_LIST, rest],
        queryFn: () => getListTemplates(rest),
        select: (res) => {
            return {
                ...res,
                data: res?.data?.map((d) => {
                    return {
                        ...d,
                        category: actualCategory?.find((c) => Number(d?.category_id) === c?.id),
                    };
                }),
            }

        },
        enabled: !!enabled
    })
};

export default useGetTemplateList;
