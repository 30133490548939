import { AiFillSetting } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { IoLayersSharp } from "react-icons/io5";
import { LiaAddressCardSolid } from "react-icons/lia";
import { MdLink, MdOutlineTitle } from 'react-icons/md';
import { TbClipboardCopy } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Empty, Image, Row, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Paragraph from 'antd/lib/typography/Paragraph';
import { CatalogListResponse } from 'apis/catalog/get-catalog/interface';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import CopyClipboardCustom from 'modules/catalog/components/product-catalog-component/copy-clipboard-custom';

import Copy from '../components/copy-clip-board';
import DisplayDesignLayer from '../components/display-design-layer';
import OverlayImage from '../components/overlay-img';

import style from './style.module.scss';

export const columns = (): ColumnsType<CatalogListResponse> => {

    return [
        {
            title: <span className={style.titleCell}>ID</span>,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 80
        },
        {
            title: <div className={'text-center'}>INFORMATION</div>,
            dataIndex: 'name',
            key: 'name',
            width: 300,
            render: (text, record: any) => {
                return (
                    <Row style={{ width: 400 }} gutter={[4, 4]}>
                        {text &&
                            <Col span={24}>
                                <Row gutter={[4, 4]}>
                                    <Col span={2}>
                                        <Tooltip title='Name'>
                                            <LiaAddressCardSolid color='#a1a3a6' />
                                        </Tooltip>
                                    </Col>
                                    <Col span={22}>
                                        <div>
                                            <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ margin: 0, marginRight: 5 }}>
                                                <CopyClipboardCustom name="catalog name" title={text || ''}>
                                                    {text}
                                                </CopyClipboardCustom>
                                            </Paragraph>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {record?.title &&
                            <Col span={24}>
                                <Row gutter={[4, 4]}>
                                    <Col span={2}>
                                        <Tooltip title='Title'>
                                            <MdOutlineTitle color='#a1a3a6' />
                                        </Tooltip>
                                    </Col>
                                    <Col span={22}>
                                        <div>
                                            <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ margin: 0, marginRight: 5 }}>
                                                <CopyClipboardCustom name="catalog name" title={record?.title || ''}>
                                                    {record?.title}
                                                </CopyClipboardCustom>
                                            </Paragraph>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        }
                        <Col span={24}>
                            <Row>
                                <Col span={2}>
                                    <Tooltip title='URL'>
                                        <MdLink color='#a1a3a6' />
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <div className='d-flex gap-1'>
                                        <Copy text={record?.url}>
                                            <span style={{ verticalAlign: 'text-bottom' }}>
                                                <TbClipboardCopy />
                                            </span>
                                        </Copy>
                                        <div className='text-truncate' style={{ width: '100%' }}>
                                            <a href={record?.url} target='_blank' style={{ color: "#69b1ff" }}>
                                                {record?.url}
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={2}>
                                    <Tooltip title='Layers'>
                                        <IoLayersSharp
                                            color='#a1a3a6'
                                            style={{ verticalAlign: 'supper' }}
                                        />
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <DisplayDesignLayer value={record?.layers} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: <span className={style.titleCell}>CATEGORY</span>,
            dataIndex: 'category',
            key: 'category',
            align: 'center',
            width: 100,
            render: (value) => {
                if (!value?.name) return null;

                return (
                    <div>
                        {value?.name}
                    </div>
                );
            },
        },
        {
            title: <span className={style.titleCell}>Number Mockups</span>,
            key: 'number_mockups',
            dataIndex: 'number_mockups',
            align: 'center',
            width: 80,
        },
        {
            title: <span className={style.titleCell}>Mockups</span>,
            dataIndex: 'mockups',
            key: 'mockups',
            align: 'center',
            render: (value) => {
                return (
                    <div className='d-flex gap-2 flex-wrap'>
                        {
                            isEmpty(value) ?
                                <div className="d-flex justify-content-center w-100">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No mockups' />
                                </div>
                                :
                                value?.map((item: any, index: number) => {
                                    return (
                                        <div key={index}>
                                            <OverlayImage
                                                originLink={item}
                                                isZoom={false}
                                                iconSize='middle'
                                                iconClose={false}
                                            >
                                                <Image
                                                    src={item}
                                                    width={100}
                                                    height={100}
                                                    style={{ objectFit: 'contain' }}
                                                    preview={false}
                                                />
                                            </OverlayImage>
                                        </div>
                                    )
                                })}
                    </div>
                );
            }
        },
        {
            title: <span className={style.titleCell}>Enable</span>,
            dataIndex: 'enable',
            key: 'enable',
            align: 'center',
            width: 80,
            render: (text) => {
                if (!text) return null

                return <FaCheckCircle color='#53c41c' />
            }
        },
        {
            title: <span className={style.titleCell}>Created At</span>,
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center',
            width: 100,
            render: (text) => {
                if (!text) return null

                return (
                    <div className='d-flex justify-content-center'>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: 80,
                            }}
                        >
                            {dayjs(text).format('YYYY/MM/DD HH:mm:ss')}
                        </div>
                    </div>
                )
            }
        },
        {
            title: <span className={style.titleCell}>Updated At</span>,
            dataIndex: 'updated_at',
            key: 'updated_at',
            align: 'center',
            width: 100,
            render: (text) => {
                if (!text) return null

                return (
                    <div className='d-flex justify-content-center'>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: 80,
                            }}
                        >
                            {dayjs(text).format('YYYY/MM/DD HH:mm:ss')}
                        </div>
                    </div>
                )
            }
        },
        {
            title: <span className={style.titleCell}>Action</span>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 40,
            render: (_, record) => {
                return (
                    <Link to={`/templates/${record.id}`} state={{ stepBack: -1 }}>
                        <Button
                            icon={<AiFillSetting />}
                            type='primary'
                        />
                    </Link>
                );
            },
        }
    ];
};
