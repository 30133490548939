import React from 'react'
import { Tag, Tooltip } from 'antd';

type Props = {
    "label": string,
    "value"?: number | string,
    "closable": boolean
    disabled: boolean,
    onClose: (e: any) => void;
    customOnClose?: (value: any) => void
    checkedAll?: boolean
    checkedNot?: boolean
    isMaxTag?: boolean
    onCheckAll?: (event?: any) => void
    onCheckNot?: (event?: any) => void
}
const style = { fontSize: 14, marginRight: 4, lineHeight: '22px', display: 'inline-flex' }
export const TagRender = (props: Props) => {
    const { label, value, onCheckAll, onCheckNot, onClose, checkedAll, checkedNot, isMaxTag, closable } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    if (checkedAll && !isMaxTag) return null

    if (checkedAll && checkedNot) {
        return (
            <>
                <Tag
                    color="blue"
                    onMouseDown={onPreventMouseDown}
                    id={value as any}
                    onClose={() => onCheckAll?.({ target: { checked: false } })}
                    closable={true}
                    style={style}
                >
                    ALL
                </Tag>
                <Tag
                    color="red"
                    onMouseDown={onPreventMouseDown}
                    id={value as any}
                    onClose={() => onCheckNot?.({ target: { checked: false } })}
                    closable={true}
                    style={style}
                >
                    NOT
                </Tag>
            </>
        )
    }

    return (
        <div>
            {
                checkedAll ?
                    <Tag
                        color="blue"
                        onMouseDown={onPreventMouseDown}
                        id={value as any}
                        onClose={() => onCheckAll?.({ target: { checked: false } })}
                        closable={true}
                        style={style}
                    >
                        ALL
                    </Tag>
                    :
                    <Tag
                        color={checkedNot ? 'red' : "default"}
                        onMouseDown={onPreventMouseDown}
                        id={value as any}
                        onClose={onClose}
                        closable={closable}
                        style={style}
                    >
                        {label}
                    </Tag>
            }
        </div>
    );
}
