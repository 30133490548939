import React, { createContext, useContext, useEffect, useState } from 'react';

export interface PageLink {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
  isRedirect?: boolean;
}

export interface PageDataContextModel {
  pageTitle?: string;
  setPageTitle: (_title: any) => void;
  pageDescription?: string;
  setPageDescription: (_description: string) => void;
  pageBreadcrumbs?: Array<PageLink>;
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
  showGoBack?: boolean;
  setShowGoBack: (_isShow: boolean) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: () => { },
  setPageBreadcrumbs: () => { },
  setPageDescription: () => { },
  setShowGoBack: () => { }
});

const PageDataProvider = ({ children }: any) => {
  const [pageTitle, setPageTitle] = useState<any>('');
  const [pageDescription, setPageDescription] = useState<string>('');
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const [showGoBack, setShowGoBack] = useState<boolean>(false);
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    showGoBack,
    setShowGoBack
  };

  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>;
};

function usePageData() {
  return useContext(PageDataContext);
}

type Props = {
  description?: string;
  breadcrumbs?: Array<PageLink>;
  children?: JSX.Element | JSX.Element[];
  showGoBack?: boolean;
};

const PageTitle = ({ children, description, breadcrumbs, showGoBack }: Props) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs, setShowGoBack } = usePageData();
  useEffect(() => {
    if (children) {
      setPageTitle(children);
    }

    return () => {
      setPageTitle('');
    };
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }

    return () => {
      setPageDescription('');
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }

    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);
  useEffect(() => {
    setShowGoBack(!!showGoBack);

    return () => {
      setShowGoBack(false);
    };
  }, [showGoBack]);

  return <></>;
};

const PageDescription: React.FC = ({ children }: any) => {
  const { setPageDescription } = usePageData();
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }

    return () => {
      setPageDescription('');
    };
  }, [children]);

  return <></>;
};

export { PageDataProvider, PageDescription, PageTitle, usePageData };
