type CamelCase<S extends string> = S extends `${infer First}-${infer Rest}` | `${infer First}_${infer Rest}`
    ? `${Lowercase<First>}${Capitalize<CamelCase<Rest>>}`
    : S;

export type ToCamelCase<T> = T extends Array<infer U>
    ? Array<ToCamelCase<U>>
    : T extends object
    ? {
          [K in keyof T as CamelCase<string & K>]: ToCamelCase<T[K]>;
      }
    : T;

export const toCamelCase = <T>(obj: T): ToCamelCase<T> => {
    if (obj === null || obj === undefined) {
        return obj as ToCamelCase<T>;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => toCamelCase(item)) as ToCamelCase<T>;
    }

    if (typeof obj === 'object') {
        const newObj: any = {};
        for (const key in obj) {
            const camelKey = key.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
            newObj[camelKey] = toCamelCase(obj[key]);
        }

        return newObj as ToCamelCase<T>;
    }

    return obj as ToCamelCase<T>;
};

export const snakeToTitleCase = (str?: string) => {
    if (!str) return '';

    return str
        .replace(/_([a-z])/g, function (match, p1) {
            return ' ' + p1.toUpperCase();
        })
        .replace(/^\w/, function (firstChar) {
            return firstChar.toUpperCase();
        });
};
