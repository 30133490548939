import { FetcherResponse } from 'interfaces/fetcher'
import { axiosInstance } from 'utils/fetcher'

import { BasicTemplate } from '../interfaces/templates'

const getListTemplates = async (params: any): Promise<FetcherResponse<BasicTemplate[]>> => {
    const data = await axiosInstance.get('api/v1/catalog-mockup-templates?all=1', {
        params
    })

    return data.data
}

export default getListTemplates