export const optionsFields = [
    {
        label: 'Select Checkbox',
        value: 'selectCheckbox'
    }
]

export const sampleDataSource = [
    {
        "value": "demo1",
        "label": "Demo 1"
    },
    {
        "value": "demo2",
        "label": "Demo 2"
    }
]

export const sampleFieldName = [
    {
        "value": "id",
        "label": "site_name"
    },
]