import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Divider } from 'antd';
import { useFilterContext } from 'components/filter/context/filter-context';
import { FieldsProp } from 'components/filter/filter';
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import SortableItem from './item';
import DeleteCustomField from './popover-confirm-delete';

const PopoverContent = () => {
    const { filterSettings, onSetFilterSettings, useSetLocalSettings } = useFilterContext();
    const [items, setItems] = useState<FieldsProp['id'][]>([]);
    const [checked, setChecked] = useState<FieldsProp['id'][]>([]);

    const fields = useMemo(() => filterSettings.fields, [filterSettings.fields]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10
            },
        })
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const cloneItems = [...items];
            const oldIndex = cloneItems.indexOf(active?.id as string);
            const newIndex = cloneItems.indexOf(over?.id as string);
            const newItems = arrayMove(items, oldIndex, newIndex);
            setItems(newItems);
            const newFilterFields = fields.map((field) => {
                const index = newItems.indexOf(field.id);

                return {
                    ...field,
                    order: index
                };
            }).sort((a, b) => a.order - b.order);

            const contextSettingValues = {
                ...filterSettings,
                fields: newFilterFields,
            }
            console.log(contextSettingValues, 'contextSettingValues')
            onSetFilterSettings(contextSettingValues);
            useSetLocalSettings(contextSettingValues);
        }
    };

    useEffect(() => {
        const visibleFields = fields.filter((field) => field?.visible === undefined ? true : !!field.visible);
        setChecked(visibleFields.map((field) => field.id));
        setItems(fields.map((field) => field.id));
    }, [fields]);

    const handleChangeChecked = (values: FieldsProp['id'][]) => {
        setChecked(values)
        const newFields = fields.map((field) => {
            return {
                ...field,
                visible: values.includes(field.id)
            }
        })
        const contextSettingValues = {
            ...filterSettings,
            fields: newFields
        }
        onSetFilterSettings(contextSettingValues);
        useSetLocalSettings(contextSettingValues);
    };

    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <Checkbox.Group onChange={handleChangeChecked} value={checked} style={{ width: '100%' }}>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', width: '100%', maxHeight: 300, overflow: 'auto', paddingRight: 8 }}
                        className='scrollbar-cs'
                    >
                        {fields.map((item) => (
                            <SortableItem key={item?.id} id={item?.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Checkbox
                                        value={item.id}
                                    >
                                        <span>{item?.label}</span>
                                    </Checkbox>
                                    <span>
                                        <DeleteCustomField field={item} />
                                    </span>
                                </div>
                                <Divider style={{ margin: '4px 0px' }} />
                            </SortableItem>
                        ))}
                    </div>
                </Checkbox.Group>
            </SortableContext>
        </DndContext>
    );
};

export default PopoverContent;
