import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { TbFilterCheck, TbFilterX } from 'react-icons/tb'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Divider, Empty, Form, Row } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { cloneDeep, isEmpty, omit, pick } from 'lodash'

import { KeyAllNotSearch } from './components/select-checkbox'
import FilterSettings from './components/settings'
import { useFilterContext } from './context/filter-context'
import { TypeKeyFormItem } from './filter-field/fields'
import { handleTransformGroupValuesSelect } from './helpers/transform-value-select'
import DisplayCurrentFilter from './display-current-filter'
import FilterField from './filter-field'

import './styles.css'

type TypeValidation = {
    isRequire?: boolean;
    validationType?: string;
    validationRegex?: string;
    validationMessage?: string;
};

type Params = {
    [key: string]: any
}

export type Field = {
    id?: any;
    label?: string;
    key: string;
    type: TypeKeyFormItem;
    mode?: 'multiple' | 'tags';
    url?: string;
    validations?: TypeValidation[];
    dataSources?: any[];
    compact?: any[];
    compactKey?: string[];
    inputType?: string;
    defaultValue?: any;
    visible?: boolean;
    isCustom?: boolean;
    customLabel?: (value: any, options?: any[]) => React.ReactNode;
}

export type FieldsProp = {
    queryFieldParams?: Params
    fieldsName?: {
        value: string
        label: string
    }
} & Field

export type FilterProps = {
    onSubmit?: (values?: any) => void
    col?: number
    styles?: {
        button?: React.CSSProperties
    }
    fields: FieldsProp[]
}

const resetArr = (a: any, b: any) => {
    const nameA = a?.id?.toUpperCase();
    const nameB = b?.id?.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
}

const FilterComponent = (props: FilterProps) => {
    const ALL = KeyAllNotSearch.AllSearch
    const ELSE = KeyAllNotSearch.ElseSearch
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const { onSubmit, col = 8, styles } = props
    const { pathname, search } = useLocation()
    const [searchQuery, setSearchQuery] = useState<{ [key: string]: any }>({})
    const formValues = handleTransformGroupValuesSelect(useWatch([], form))

    const [isShowMoreFilter, setIsShowMoreFilter] = useState(false)
    const advanceRef = useRef(null) as any

    const { filterSettings, onSetFilterSettings, useGetLocalSettings, useSetDefaultSettings } = useFilterContext()


    const memoLocalSettingFilter = useGetLocalSettings()

    useEffect(() => {
        const newFieldsMemoLocalSettingFilter = memoLocalSettingFilter?.fields?.filter((i) => !i?.isCustom) || []
        const originalLocalFields = newFieldsMemoLocalSettingFilter.map((n) => omit(n, ['visible', 'order']))
        const cloneOriginalLocalFields = cloneDeep(originalLocalFields)
        const cloneFields = cloneDeep(props.fields)
        const isDiffField = JSON.stringify(cloneOriginalLocalFields.sort(resetArr)) !== JSON.stringify(cloneFields.sort(resetArr))
        const reset = (!isEmpty(props.fields) &&
            (newFieldsMemoLocalSettingFilter?.length !== props.fields?.length || !newFieldsMemoLocalSettingFilter?.every((f) => props.fields.map((p) => p.id).includes(f.id))))
            || isDiffField

        if (reset) {
            onSetFilterSettings({ fields: props.fields })
        } else {
            onSetFilterSettings(isEmpty(newFieldsMemoLocalSettingFilter) ? { fields: props.fields } : memoLocalSettingFilter)
        }
        useSetDefaultSettings({ fields: props.fields })
    }, [props.fields, memoLocalSettingFilter])

    const fields = useMemo(() => {
        const initFields = filterSettings?.fields || []

        return initFields.filter((f) => f.visible !== false)
    }, [filterSettings])

    const actualKeyFields = fields.map((f) => {
        if (f.compactKey) {
            return f.compactKey.map((key) => key)
        } else {
            return f.key
        }
    }).flat()

    const compactFields = fields.filter((f) => f.type === TypeKeyFormItem.Compact) || []

    const handleSearch = async (values: any) => {
        const originalValues = handleTransformGroupValuesSelect(values) || {}
        const newValues = Object.entries(originalValues) || []
        const specialQuery = newValues.filter(([_, value]) =>
            Array.isArray(value) && value.length > 0 &&
            (value.includes(ALL) || value.includes(ELSE)))
        const normalQuery = newValues.filter(([key, _]) => !specialQuery.flat().includes(key))
        const queryString: Params = {}
        const specialQueryString: Params = {
            [ALL]: [],
            [ELSE]: []
        }
        normalQuery.forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    queryString[key] = value.join(',')
                }
            } else if (value) {
                if (typeof value === 'object') {
                    if (Object.values(value || {}).every((v) => !!v)) {
                        queryString[key] = Object.values(value).join(',')
                    }
                } else {
                    if (compactFields.map((f) => f.compactKey).flat().includes(key)) {
                        const i = compactFields.find((f) => f?.compactKey?.includes(key))
                        const firstIndex = i?.compactKey?.[0]
                        const lastIndex = i?.compactKey?.[1]
                        if (!!firstIndex && !!lastIndex && originalValues?.[firstIndex] && originalValues?.[lastIndex]) {
                            queryString[key] = value
                        } else {
                            delete queryString[key]
                        }
                    } else {
                        queryString[key] = value
                    }
                }
            }
        })
        specialQuery.forEach(([key, value]: [string, any]) => {
            if (value?.includes(ALL) && value?.includes(ELSE)) {
                specialQueryString[ALL].push(key)
                specialQueryString[ELSE].push(key)
            } else {
                value.forEach((v: string) => {
                    if (v === ALL) {
                        specialQueryString[ALL].push(key)
                    } else if (v === ELSE) {
                        specialQueryString[ELSE].push([key, value.filter((item: any) => ![ALL, ELSE].includes(item))])
                    }
                })
            }
        })
        const elseSearchKeys = [] as Params
        const elseSearchValues = [] as Params
        specialQueryString[ELSE].forEach((s: any) => {
            if (Array.isArray(s)) {
                if (isEmpty(s[1])) return
                elseSearchKeys.push(s[0])
                elseSearchValues.push(s)
            } else {
                elseSearchKeys.push(s)
            }
        })

        const allSearchString = specialQueryString[ALL].join(',')
        const elseSearchKeysString = elseSearchKeys.join(',')
        const queryElseSearchValues = {} as Params
        elseSearchValues.forEach(([key, value]: [string, any]) => {
            queryElseSearchValues[key] = value.join(',')
        })

        const emptySpecialSearch = isEmpty(specialQueryString[ALL]) && isEmpty(specialQueryString[ELSE])
        const search = emptySpecialSearch ?
            queryString :
            {
                ...queryString,
                ...(allSearchString && { [ALL]: allSearchString }),
                ...(elseSearchKeysString && { [ELSE]: elseSearchKeysString }),
                ...(!isEmpty(queryElseSearchValues) && queryElseSearchValues),
            }
        const omitQuery = omit(searchQuery, Object.keys(search))
        const omitPick = omit(omitQuery, [...actualKeyFields, ALL, ELSE])
        const pickAllElse = pick({ ...search, ...omitPick }, [ALL, ELSE])
        const finalOmit = omit({ ...search, ...omitPick }, [ALL, ELSE])

        navigate({
            pathname,
            search: createSearchParams({ ...pickAllElse, ...finalOmit, page: '1' }).toString(),
        })
        // onSubmit && onSubmit({ ...pickAllElse, ...finalOmit })
    }

    const resetQuery = useMemo(() => {
        const url = new URLSearchParams(search)
        const obUrl = Object.fromEntries(url)
        fields.forEach((f) => {
            const key = f?.key
            if (obUrl.hasOwnProperty(key)) {
                delete obUrl?.[key]
            }
            if (f?.compactKey) {
                f?.compactKey.forEach((key) => {
                    if (obUrl.hasOwnProperty(key)) {
                        delete obUrl?.[key]
                    }
                })
            }
            delete obUrl[ALL]
            delete obUrl[ELSE]
        })

        return obUrl
    }, [search, fields])

    const handleClear = useCallback(() => {
        form.resetFields()
        onSubmit && onSubmit()
        navigate({
            pathname,
            search: createSearchParams({ ...resetQuery, page: '1' }).toString(),
        });
    }, [form, navigate, onSubmit, pathname, resetQuery])

    useEffect(() => {
        const url = new URLSearchParams(search)
        const obUrl = Object.fromEntries(url)
        setSearchQuery(obUrl)
    }, [pathname, search])

    const onRemove = useCallback(({ key, value, options }: { key?: string, value?: string, options?: any }) => {
        const newSearchQuery = { ...searchQuery }
        const newFormValues = { ...formValues }
        if (!key) return
        const stringValue = value?.toString()
        if (options) {
            options?.queryKey?.forEach((o: string) => {
                const isAllSearch = newSearchQuery?.[ALL]?.split(',').includes(o)
                const isAllElseSearch = newSearchQuery?.[ELSE]?.split(',').includes(o)
                const newAllSearch = newSearchQuery?.[ALL]?.split(',').filter((v: any) => v != o).join(',')
                const newElseSearch = newSearchQuery?.[ELSE]?.split(',').filter((v: any) => v != o).join(',')

                if (newSearchQuery[o]) {
                    const isElseSearch = newSearchQuery?.[ELSE]?.includes(o)
                    if (isElseSearch) {
                        newSearchQuery[ELSE] = newElseSearch
                        newFormValues[o] = []
                    }
                    delete newSearchQuery[o]
                    const final = typeof newFormValues[o] === 'string' ? undefined : []
                    newFormValues[o] = final
                    form.setFieldValue(o, final)
                }

                if (isAllElseSearch && isAllSearch) {

                    newSearchQuery[ALL] = newAllSearch
                    newSearchQuery[ELSE] = newElseSearch
                    newFormValues[o] = []
                    form.setFieldValue(o, [])
                } else {
                    if (isAllElseSearch) {
                        newSearchQuery[ELSE] = newElseSearch
                        newFormValues[o] = []
                        form.setFieldValue(o, [])
                    }

                    if (isAllSearch) {
                        newSearchQuery[ALL] = newAllSearch
                        newFormValues[o] = []
                        form.setFieldValue(o, [])
                    }
                }
            })
        } else if (!!stringValue) {
            if (stringValue === KeyAllNotSearch.AllValue) {
                const newAllSearch = newSearchQuery?.[ALL]?.split(',').filter((v: any) => v != key).join(',')
                newSearchQuery[ALL] = newAllSearch
                newFormValues[key] = []
                form.setFieldValue(key, [])
            } else {
                const newValue = newFormValues[key].filter((v: any) => v != value) || []
                newFormValues[key] = newValue
                const newSearchQueryKey = newValue.filter((v: any) => v != ELSE)
                if (isEmpty(newSearchQueryKey)) {
                    const isElseSearch = newValue.includes(ELSE)
                    if (isElseSearch) {
                        newSearchQuery[ELSE] = newSearchQuery?.[ELSE].split(',').filter((v: any) => v != key).join(',')
                        newFormValues[key] = []
                    }
                    delete newSearchQuery[key]
                    form.setFieldValue(key, [])
                } else {
                    newSearchQuery[key] = newSearchQueryKey.join(',')
                    form.setFieldValue(key, newValue)
                }
            }
        }

        if (!newSearchQuery?.[ELSE]) {
            delete newSearchQuery[ELSE]
        }
        if (!newSearchQuery?.[ALL]) {
            delete newSearchQuery[ALL]
        }
        navigate({
            pathname,
            search: createSearchParams(newSearchQuery).toString(),
        });
    }, [searchQuery, formValues])

    const primaryFilterQuantity = 24 / col * 2
    const primaryFields = useMemo(() => {
        return fields.filter((_, index) => index < primaryFilterQuantity)
    }, [fields, primaryFilterQuantity])

    const advanceFields = useMemo(() => {
        return fields.filter((_, index) => index >= primaryFilterQuantity)
    }, [fields, primaryFilterQuantity])

    const onShowMoreFilter = () => {
        setIsShowMoreFilter(!isShowMoreFilter)
    }

    const showMoreFilter = isShowMoreFilter && !isEmpty(primaryFields)

    return (
        <Row
            style={{ marginTop: 8, zIndex: 1 }}
            className='filter-component'
        >
            <Card
                size='small'
                style={{ width: '100%' }}
                styles={{ body: { background: 'white', borderRadius: 8, overflow: 'hidden' } }}
            >
                <Col span={24}>
                    <Form form={form} onFinish={handleSearch} layout='vertical'>
                        <Row gutter={[16, 0]} className='m-0'>
                            {isEmpty(primaryFields) ?
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description='No filter available'
                                        style={{ marginBlock: 0 }}
                                    />
                                </div>
                                :
                                primaryFields.map((f, index) => {
                                    const isCompact = f.type === TypeKeyFormItem.Compact

                                    return (
                                        <Col span={col} key={index}>
                                            <Form.Item
                                                name={!isCompact ? f?.key : undefined}
                                                label={<span style={{ color: '#525252' }}>{f?.label}</span>}
                                                style={{ marginBottom: 4 }}
                                            >
                                                <FilterField
                                                    field={f}
                                                    form={form}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }
                            <Col
                                ref={advanceRef}
                                span={24}
                                className={showMoreFilter ? 'advance animate__animated animate__lightSpeedInRight' : 'advance animate__animated animate__fadeOutDown'}
                                style={{ height: showMoreFilter ? advanceRef?.current?.scrollHeight : '0px' }}
                            >
                                <Row gutter={[16, 0]}>
                                    {
                                        advanceFields.map((f, index) => {
                                            const isCompact = f.type === TypeKeyFormItem.Compact

                                            return (
                                                <Col span={col} key={index}>
                                                    <Form.Item
                                                        name={!isCompact ? f?.key : undefined}
                                                        label={<span style={{ color: '#525252' }}>{f?.label}</span>}
                                                        style={{ marginBottom: 4 }}
                                                    >
                                                        <FilterField
                                                            field={f}
                                                            form={form}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Divider style={{ margin: '4px 0px 6px 0px' }} />
                        </Row>
                        <Row gutter={[16, 0]}>
                            <div style={{ display: 'flex', gap: 8, position: 'relative', width: '100%', minHeight: 34, zIndex: 1 }}>
                                <div style={{ marginRight: 335 }}>
                                    <DisplayCurrentFilter
                                        fields={fields}
                                        onRemove={onRemove}
                                    />
                                </div>
                                <div style={{ textAlign: 'end', position: 'absolute', right: 8 }}>
                                    <Form.Item style={{ marginBottom: 6 }}>
                                        <Button
                                            type='default'
                                            icon={<TbFilterX />}
                                            onClick={handleClear}
                                            className='me-2'
                                            style={styles?.button}
                                        >
                                            Clear
                                        </Button>
                                        {advanceFields.length > 0 && (
                                            <Button
                                                type='dashed'
                                                icon={isShowMoreFilter ? <FaChevronUp /> : <FaChevronDown />}
                                                style={styles?.button}
                                                className='me-2 btn-show-more-filter'
                                                onClick={onShowMoreFilter}
                                            >
                                                Show more
                                            </Button>
                                        )}
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            icon={<TbFilterCheck />}
                                            style={styles?.button}
                                        >
                                            Filter
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Row>
                    </Form>
                </Col>
                <FilterSettings onClear={handleClear} />
            </Card>
        </Row >
    )
}

export default FilterComponent