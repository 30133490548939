import { Navigate, Route, Routes } from "react-router-dom";

import { QueryRequestProvider } from "./core/query-request-provider";
import { TemplatesProvider } from "./core/templates-provider";
import DetailTemplates from "./detail";
import TemplatesList from "./list";

const Templates = () => {
  return (
    <Routes>
      <Route
        path="/list"
        element={
          <QueryRequestProvider>
            <TemplatesProvider>
              <TemplatesList />
            </TemplatesProvider>
          </QueryRequestProvider>
        }
      />
      <Route
        path="/:id"
        element={
          <QueryRequestProvider>
            <DetailTemplates />
          </QueryRequestProvider>
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Templates;
