import { FetcherResponse } from 'interfaces/fetcher'
import { axiosInstance } from 'utils/fetcher'

const getListCatalogDesign = async (params: any): Promise<FetcherResponse<any[]>> => {
    const data = await axiosInstance.get('api/v1/catalog-designs', {
        params
    })

    return data.data
}

export default getListCatalogDesign