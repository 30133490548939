import React, { useMemo } from 'react';
import { AiOutlineSync } from 'react-icons/ai';
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { convertNormalizeString } from 'modules/designs/helpers/convert-nomalize-string';
import { sortArray } from 'modules/designs/helpers/sort';

export type SelectCustomProps = SelectProps<DefaultOptionType, Omit<DefaultOptionType, 'options'>> & {
  options?: any[];
  children?: React.ReactNode;
  isSearchApi?: boolean;
};
const CustomLoadingIndicator = () => {
  return <AiOutlineSync style={{ verticalAlign: '-0.15rem' }}

    className={'rotate-infinite color-primary'}
  />
};

const SelectCustom: React.FC<SelectCustomProps> = ({ fieldNames, options, children, style, isSearchApi = false, ...restProps }) => {
  const [searchValue, setSearchValue] = React.useState('');

  // const newOptions: DefaultOptionType[] = useMemo(() => {
  //   return options || []
  // }, [options])

  // options?.map((item: any) => ({
  //   value: item[fieldNames?.value || 'value' || "id"], // Adjust based on your Shipping type
  //   label: item[fieldNames?.label || 'label' || "name"], // Adjust based on your Shipping type
  //   ...item
  // }))

  const filterOptions = (input: any, option: any) => {
    const label = option[fieldNames?.label || "label"]
    const oriString = convertNormalizeString(label)
    const stringToCompare = convertNormalizeString(input)
    const result = (`${oriString ?? ''}`?.toLowerCase() ?? '').indexOf(stringToCompare?.toLowerCase()) > -1;

    return result
  }

  const newSortOptions = useMemo(() => {
    if (!options) return undefined

    return sortArray(options, [fieldNames?.label || "label"])
  }, [options, fieldNames?.label])

  const newChildren = useMemo(() => {
    if (!children) return null

    return sortArray(children as any[], ['props', fieldNames?.label || "label"])
  }, [children, fieldNames?.label])

  return (
    <Select
      showSearch
      allowClear
      fieldNames={{
        ...fieldNames
      }}
      onBlur={() => setSearchValue('')}
      searchValue={searchValue}
      onSearch={(value: any) => setSearchValue(value)}
      filterOption={!isSearchApi && filterOptions}
      options={newSortOptions}
      style={{
        width: '100%',
        ...style
      }}
      suffixIcon={restProps.loading ? <CustomLoadingIndicator /> : undefined}
      {...restProps}
    >
      {!newSortOptions && newChildren}
    </Select>
  );
};

export default SelectCustom;
