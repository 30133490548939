import { FormInstance } from 'antd'
import { FieldsProp } from 'components/filter/filter'

import { formFieldsFilter, TypeKeyFormItem } from '../fields'

type Props = {
    field: FieldsProp,
    options: any[],
    loading?: boolean,
    form?: FormInstance,
    onChange?: (value: any) => void
    value: any
}

const FilterFormItem = (props: Props) => {
    const { field, options, form, onChange, value, loading } = props
    const Child = formFieldsFilter.find((f) => f.key.includes(field?.type as TypeKeyFormItem))?.component as any
    if (!Child) return null

    const newOptions = field?.url ? options : field?.dataSources
    const compactKeyProps = field?.type === TypeKeyFormItem.Compact ? { compactKey: field?.compactKey } : {}
    const loadingProps = [TypeKeyFormItem.Select, TypeKeyFormItem.SelectCheckbox].includes(field?.type) ? { loading } : {}

    return (
        <Child
            allowClear
            form={form}
            value={value}
            mode={field?.mode}
            onChange={onChange}
            options={newOptions}
            fieldKey={field?.key}
            compact={field?.compact}
            customLabel={field?.customLabel}
            {...compactKeyProps}
            {...loadingProps}
            placeholder={`Please enter ${field?.label || 'values'}...`}
        />
    )
}

export default FilterFormItem