import { useMemo } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { FaRegCircleXmark } from 'react-icons/fa6'
import { TbClipboardCopy } from 'react-icons/tb'
import { Card } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { BasicTemplate } from 'modules/template/apis/interfaces/templates'
import Copy from 'modules/template/components/copy-clip-board'
import DisplayDesignLayer from 'modules/template/components/display-design-layer'
import { useQueryRequest } from 'modules/template/core/query-request-provider'
import ModalTemplate from 'modules/template/list/modal-template'

import ModalUpload from './modal-upload'

type Props = {
    data?: BasicTemplate
    fetching?: boolean
    loading?: boolean
}

const formatDate = (date?: string) => {
    if (!date) return '--'

    return dayjs(date).format('DD/MM/YYYY HH:mm:ss')
}

const TemplateInfomation = ({ data, fetching, loading }: Props) => {
    const {
        created_at,
        updated_at,
        enable,
        number_mockups,
        url,
        layers = [],
        title
    } = data || {}

    const { actualCategory = [] } = useQueryRequest();

    const category = useMemo(() => {
        return actualCategory.find((c) => Number(c?.id) === Number(data?.category_id))
    }, [actualCategory, data?.category_id])

    return (
        <Card
            size='small'
            title="Detail"
            loading={fetching}
            extra={!loading && <ModalTemplate data={data} />}
            className='h-100'
            styles={{
                body: {
                    height: '100%'
                }
            }}
        >
            <div>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Title:</span>
                    <span>{title ?? '--'}</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Number Mockups:</span>
                    <span>{number_mockups ?? 0}</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Category:</span>
                    <span>{category?.name ?? '--'}</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Created At:</span>
                    <span>{formatDate(created_at)}</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Updated At:</span>
                    <span>{formatDate(updated_at)}</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Enable:</span>
                    <span>{enable ? <FaCheckCircle color='#53c41c' /> : <FaRegCircleXmark color='#f5362c' />}</span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>URL:</span>
                    <span className='d-block'>
                        <div className='d-flex gap-1'>
                            <Copy text={url}>
                                <TbClipboardCopy />
                            </Copy>
                            <div className='text-truncate' style={{ maxWidth: 250 }}>
                                <a href={url} target='_blank'>
                                    {url}
                                </a>
                            </div>
                            <div>
                                <ModalUpload data={data} />
                            </div>
                        </div>
                    </span>
                </p>
                <p className='d-flex justify-content-between'>
                    <span style={{ fontWeight: 500 }}>Layers:</span>
                    <span>
                        {isEmpty(layers) ? '--' : <DisplayDesignLayer value={layers} style={{ justifyContent: 'end' }} />}
                    </span>
                </p>
            </div>
        </Card>
    )
}

export default TemplateInfomation