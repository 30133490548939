import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

type Props = {
    id: string;
    children: React.ReactNode;
}

const SortableItem = ({ id, children }: Props) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div>
                {children}
            </div>
        </div>
    );
}
export default SortableItem