import { createContext, memo, useCallback, useMemo, useState } from 'react'
import ConfirmDialog from 'components/common/confirm-dialog'


interface ConfirmDialogData {
  isShow: boolean
  title: string
  description: string
  buttonCancelLabel: string
  buttonOkLabel: string
  onOk: () => void
  onCancel: () => void
}

type FnGetDialogWarningResult = (data: {
  title?: string
  description: string
  buttonCancelLabel?: string
  buttonOkLabel?: string
}) => Promise<boolean>

interface ConfirmDialogState {
  confirmDialogData: ConfirmDialogData
  getDialogConfirmResult: FnGetDialogWarningResult
}

interface ConfirmDialogProviderProps {
  children: React.ReactNode
}

const initDialogWarningData = {
  isShow: false,
  title: 'Confirm',
  description: '',
  buttonCancelLabel: 'No',
  buttonOkLabel: 'Yes',
  onOk: () => { },
  onCancel: () => { },
}

export const ConfirmDialogContext = createContext<ConfirmDialogState>({
  confirmDialogData: initDialogWarningData,
  getDialogConfirmResult: () => Promise.resolve(false),
})

export const ConfirmDialogProvider = memo(
  ({ children }: ConfirmDialogProviderProps) => {
    const [confirmDialogData, setConfirmDialogData] = useState(
      initDialogWarningData,
    )

    const getDialogConfirmResult = useCallback(
      async ({
        title = initDialogWarningData.title,
        description,
        buttonCancelLabel = initDialogWarningData.buttonCancelLabel,
        buttonOkLabel = initDialogWarningData.buttonOkLabel,
      }: {
        title?: string
        description: string
        buttonCancelLabel?: string
        buttonOkLabel?: string
      }): Promise<boolean> => {
        return await new Promise((resolve, reject) => {
          setConfirmDialogData({
            isShow: true,
            title,
            description,
            buttonCancelLabel,
            buttonOkLabel,
            onCancel: () => {
              setConfirmDialogData(initDialogWarningData)
              resolve(false)
            },
            onOk: () => {
              setConfirmDialogData(initDialogWarningData)
              resolve(true)
            },
          })
        })
      },
      [],
    )

    const contextValues = useMemo(() => {
      return {
        confirmDialogData,
        getDialogConfirmResult,
      }
    }, [confirmDialogData, getDialogConfirmResult])

    return (
      <ConfirmDialogContext.Provider value={contextValues}>
        {children}
        <ConfirmDialog />
      </ConfirmDialogContext.Provider>
    )
  },
)

ConfirmDialogProvider.displayName = 'ConfirmDialogProvider'
