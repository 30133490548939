import { useQuery } from "react-query"
import getActualCategoryList from 'apis/catalog/get-list-actual-category'
import { ActualCategoryListRequest } from 'apis/catalog/get-list-actual-category/interface'
import { QUERY_KEYS } from 'constant/query'


const useGetActualCategoryList = (params: ActualCategoryListRequest) => {
    return useQuery([QUERY_KEYS.ACTUAL_CATEGORY, params], () => getActualCategoryList(params))

}

export default useGetActualCategoryList