import { toast } from "react-toastify";
import axios from "axios";
import * as authHelper from 'helpers/auth'


const API_URL = process.env.REACT_APP_API_URL


// export const instance = axios.create({ baseURL: API_URL })
// instance.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'

// const CancelToken = axios.CancelToken

const axiosInstance = axios.create({
    baseURL: `${API_URL}`,
    // timeout: 20000,
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
    // responseType: "blob",

    // cancelToken: new CancelToken(function executor(c) {
    //     // An executor function receives a cancel function as a parameter
    //     cancel = c
    // })
})

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {

        if (error.response && error.response.status === 401) {
            authHelper.removeUser()
            authHelper.removeAuth();
            window.location.href = "/login"

            return Promise.resolve(error.response)
        } else if (error.response) {
            return Promise.resolve(error.response)
        }

        return Promise.reject(error)
    },
)
const setUpToken = (token: any) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
// export function setupAxios(token?: any) {
//     // if (auth && auth.access_token) {
//     //   axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token || auth.access_token}`
//     // }

//     axiosInstance.interceptors.request.use(
//       (config: {headers: {Authorization: string}}) => {
//         const auth = authHelper.getAuth()
//         if (auth && auth.access_token) {
//           config.headers.Authorization = `Bearer ${token || auth }`
//         }

//         return config
//       },
//       (err: any) => Promise.reject(err)
//     )
//   }

export { axiosInstance, setUpToken }
