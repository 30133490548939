import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import LayoutSplashScreen from 'components/common/layout-splash-screen'
import * as authHelper from 'helpers/auth'
import { AuthModel, UserModel } from 'interfaces/auth'
import { setUpToken } from 'utils/fetcher'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (data: any) => void
  currentUser: Partial<UserModel> | any
  setCurrentUser: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<any> = ({ children }) => {
  const [user, setUser] = useState('')
  const [auth, setAuth] = useState<any>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<any>()

  const saveAuth = useCallback((auth: any) => {
    setAuth(auth?.access_token)
    if (auth?.access_token) {
      authHelper.setAuth(auth?.access_token)
    } else {
      authHelper.removeAuth()
    }
  }, [])

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined);
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<any> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const navigate = useNavigate()

  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    if (auth) {
      setCurrentUser(auth)
      setShowSplashScreen(false)
      setUpToken(auth)
    } else {
      logout()
      setShowSplashScreen(false)
      // navigate("/login")
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthInit, AuthProvider, useAuth }
