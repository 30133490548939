import React, { createContext, useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FieldsProp } from '../filter';

type SelectOptionsValue = {
    field: FieldsProp
    options: any[]
    loading?: boolean
}

type FilterProviderProps = {
    children: React.ReactNode
}

type SettingFilterState = {
    fields: FieldsProp[]
    colors?: {
        bgItemCurrentFilter?: string
    }
}


const initSettingFilter: SettingFilterState = {
    fields: [],
    colors: {
        bgItemCurrentFilter: '#efeff1',
    }
}

type FilterContextType = {
    selectOptions: SelectOptionsValue[]
    onSetSelectOptions: (values: SelectOptionsValue) => void
    filterSettings: SettingFilterState
    onSetFilterSettings: ({ fields, colors }: SettingFilterState) => void
    useSetLocalSettings: (values: SettingFilterState) => void
    useGetLocalSettings: () => SettingFilterState
    pathname?: string
    useSetDefaultSettings: (values: SettingFilterState) => void
    useGetDefaultSettings: () => SettingFilterState
    onResetToDefaultSettings: () => void
}

const initContextValues: FilterContextType = {
    selectOptions: [],
    onSetSelectOptions: () => { },
    filterSettings: initSettingFilter,
    onSetFilterSettings: () => { },
    useSetLocalSettings: () => { },
    useGetLocalSettings: () => {
        return initSettingFilter
    },
    pathname: '',
    useSetDefaultSettings: () => { },
    useGetDefaultSettings: () => {
        return initSettingFilter
    },
    onResetToDefaultSettings: () => { }
}


enum ESettingFilterAction {
    SET_FIELDS_FILTER = 'setFieldsFilter',
    SET_COLOR = 'setColor'
}

type SettingFilterAction = { type: ESettingFilterAction, payload?: any }

const reducerSettings = (state: SettingFilterState, action: SettingFilterAction): SettingFilterState => {
    const { type, payload } = action

    switch (type) {
        case ESettingFilterAction['SET_FIELDS_FILTER']:
            return { ...state, fields: payload }
        default:
            return state
    }
}

const FilterContext = createContext<FilterContextType>(initContextValues)

const FilterProvider = ({ children }: FilterProviderProps) => {
    const { pathname } = useLocation();
    const path = 'setting-filter' + pathname.split('/').join('-');
    const defaultPath = 'default-setting-filter' + pathname.split('/').join('-');

    const [filterSettings, dispatch] = useReducer(reducerSettings, initSettingFilter);
    const [selectOptions, setSelectOptions] = useState<FilterContextType['selectOptions']>(initContextValues['selectOptions']);

    const onSetSelectOptions = ({ field, options, loading }: SelectOptionsValue) => {
        setSelectOptions((prev) => {
            const newSelectOptions = [...prev];
            const index = newSelectOptions.findIndex((option) => option?.field?.id === field?.id);
            if (index > -1) {
                newSelectOptions[index] = { field: field, options, loading }
            } else {
                newSelectOptions.push({ field, options, loading })
            }

            return newSelectOptions

        })
    }

    const onSetFilterSettings = ({ fields, colors }: SettingFilterState) => {
        dispatch({ type: ESettingFilterAction.SET_FIELDS_FILTER, payload: fields })
    }

    const useSetDefaultSettings = useCallback((values: SettingFilterState) => {
        const visibleFields = values.fields.map((field) => ({ ...field, visible: true }));
        const newValues = { ...initSettingFilter, ...values, fields: visibleFields }
        localStorage.setItem(defaultPath, JSON.stringify(newValues));
    }, [])

    const useGetDefaultSettings = useCallback(() => {
        return JSON.parse(localStorage.getItem(defaultPath) || '{}')
    }, [defaultPath])


    const useSetLocalSettings = (values: SettingFilterState) => {
        localStorage.setItem(path, JSON.stringify(values));
    }

    const onResetToDefaultSettings = () => {
        const defaultSetting = JSON.parse(localStorage.getItem(defaultPath) || '{}')
        localStorage.setItem(path, JSON.stringify(defaultSetting));
        onSetFilterSettings(defaultSetting)
    }

    const memoLocalSettingFilter = useMemo(() => {
        const localSettingFilter = JSON.parse(localStorage.getItem(path) || '{}')

        return localSettingFilter
    }, [path])

    const useGetLocalSettings = useCallback(() => {
        return memoLocalSettingFilter
    }, [memoLocalSettingFilter])

    return (
        <FilterContext.Provider
            value={{
                pathname,
                selectOptions,
                filterSettings,
                onSetSelectOptions,
                onSetFilterSettings,
                useSetLocalSettings,
                useGetLocalSettings,
                useSetDefaultSettings,
                useGetDefaultSettings,
                onResetToDefaultSettings
            }}>
            {children}
        </FilterContext.Provider>
    )
}

const useFilterContext = () => useContext(FilterContext);

export { FilterProvider, useFilterContext };

