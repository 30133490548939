import { toast } from 'react-toastify';

export const notify = (response: any, type: string) => {
    if (response.success) {
        return toast.success(`🦄 ${type} Succeed!`, {
            position: 'top-right',
            autoClose: 500,
            hideProgressBar: true,
            theme: 'light',
            pauseOnHover: false
        });
    } else {
        return toast.error(`🦄 ${response.message}`, {
            position: 'top-right',
            autoClose: 500,
            hideProgressBar: true,
            theme: 'light',
            pauseOnHover: false
        });
    }
};

const convertMessErr = (errors: any): string => {
    if (!errors) return '';
    const messageString =
        typeof errors === 'string'
            ? errors
            : Array.isArray(errors)
            ? errors?.map((item: any) => convertMessErr(item)).join('\n')
            : typeof errors === 'object'
            ? Object.keys(errors)
                  ?.map((item: any) => {
                      return convertMessErr(errors[item]);
                  })
                  .join('\n')
            : 'Oops! Something went wrong!';

    return messageString;
};

export const notifyv2 = (response: any, type?: any) => {
    if (!!response?.success) {
        const message = typeof response.message === 'string' ? response.message : `${type || ''} Successfully!`;

        return toast.success(`🦄 ${message}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            theme: 'light',
            pauseOnHover: false
        });
    } else {
        const message = convertMessErr(response?.errors) || `${response?.message}` || `Some thing went wrong!`;

        if (type === 'warning') {
            return toast.warning(message, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: true,
                theme: 'light',
                pauseOnHover: false
            });
        } else {
            return toast.error(message, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: true,
                theme: 'light',
                pauseOnHover: false
            });
        }
    }
};
