import { FetcherResponse } from 'interfaces/fetcher';
import { axiosInstance } from 'utils/fetcher';

export type TypeRedirectLoginMs = {
    code?: string;
};
const getRedirectLoginMs = async (params: TypeRedirectLoginMs): Promise<FetcherResponse<any[]>> => {
    const { data: responseData } = await axiosInstance.get<FetcherResponse<any>>(
        `api/v1/sign-in-social/${params?.code}`
    );

    return responseData;
};

export default getRedirectLoginMs;
