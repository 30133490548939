import { useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { Button, Modal, Popover } from 'antd';
import { useFilterContext } from "components/filter/context/filter-context";

import PopoverContent from "./content";
import ModalAddField from "./modal-add-field";

import './styles.css';

type FilterSettingsProps = {
    onClear: () => void
}
const FilterSettings = ({ onClear }: FilterSettingsProps) => {
    const { onResetToDefaultSettings } = useFilterContext();
    const [modal, contextHolder] = Modal.useModal();
    const [visible, setVisible] = useState(false)

    const onSetVisible = () => {
        setVisible(!visible)
    }

    const confirm = (type: 'setting' | 'reset') => () => {
        if (type === 'setting') {
            modal.confirm({
                title: 'Confirm',
                content: 'This action will clear the current filter if any!',
                okText: 'Ok',
                cancelText: 'Cancel',
                onOk: () => {
                    onClear()
                    onSetVisible()
                },
            });
        } else {
            modal.confirm({
                title: 'Confirm',
                content: 'Are you sure you want to reset the filter?',
                okText: 'Ok',
                cancelText: 'Cancel',
                onOk: () => {
                    onResetToDefaultSettings()
                },
            });
        }
    };

    return (
        <>
            <div className="setting-filter">
                <Popover
                    title={
                        <div>
                            <div style={{ position: 'relative' }}>
                                <span>
                                    Settings:
                                </span>
                                <span style={{ position: 'absolute', right: 0, top: -4 }}>
                                    <ModalAddField />
                                </span>
                            </div>
                            <div>
                                <small style={{ color: 'orange', fontWeight: 400 }}>
                                    (drag and drop to reorder)
                                </small>
                            </div>
                        </div>
                    }
                    content={() => (
                        <div style={{ zIndex: 2000 }}>
                            <PopoverContent />
                            <div style={{ display: 'flex', gap: 8 }}>
                                <Button
                                    type="primary"
                                    onClick={confirm('reset')}
                                    danger
                                    ghost
                                    size="small"
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                    )}
                    autoAdjustOverflow={false}
                    getPopupContainer={(triggerNode) => triggerNode?.parentElement as any}
                    trigger={['click']}
                    placement="bottomRight"
                    open={visible}
                    onOpenChange={() => {
                        if (visible) {
                            onSetVisible()
                        } else {
                            confirm('setting')()
                        }
                    }}
                >
                    <Button
                        type="primary"
                        icon={<AiFillSetting />}
                        ghost
                        size="small"
                    />
                </Popover>
            </div>
            {contextHolder}
        </>
    )
}

export default FilterSettings