import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Col, Form, Modal, Row } from 'antd';
import { QUERY_KEYS } from 'constant/query';
import { notify } from 'modules/catalog/components/notify-component';
import useGetCatalogDesignList from 'modules/designs/data/hooks/catalog-design/use-get-catalog-design-list';
import createReviewRequest, { CreateReviewRequest } from 'modules/template/apis/create-review-request';
import { BasicTemplate } from 'modules/template/apis/interfaces/templates';
import SelectCustom from 'modules/template/components/select-custom';
import SelectDebounce from 'modules/template/components/select-debounce';

type Props = {
    data?: BasicTemplate
}

const MappingReview = ({ data }: Props) => {
    const queryClient = useQueryClient()
    const [form] = Form.useForm()

    const [searchCatalogDesignValue, setSearchCatalogDesignValue] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchCatalogDesign = useGetCatalogDesignList({
        enabled: isModalOpen,
        limit: 100,
        minimize: 1,
        search_input: 'Anywhere',
        search_keyword: searchCatalogDesignValue
    })
    const mutationCreate = useMutation({ mutationFn: (params: CreateReviewRequest) => createReviewRequest(params) })

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (values: any) => {
        const result = await mutationCreate.mutateAsync({
            id: data?.id,
            catalog_design_id: values?.catalog_design_id
        })
        notify(result, 'Create')
        if (result?.success) {
            queryClient.invalidateQueries([QUERY_KEYS.GET_TEMPLATE_DETAIL])
            handleCancel()
        }
    }

    const handleAfterClose = () => {
        form.resetFields()
    }

    const onSearchApi = (q: string) => {
        setSearchCatalogDesignValue(q?.trim())
    }

    return (
        <>
            <Button
                type="primary"
                onClick={showModal}
                icon={<AiOutlinePlus />}
            >
                Create Review
            </Button>
            <Modal
                title="Create Review"
                open={isModalOpen}
                onOk={form.submit}
                onCancel={handleCancel}
                afterClose={handleAfterClose}
                confirmLoading={mutationCreate.isLoading}
            >
                <Form
                    labelAlign="left"
                    layout='vertical'
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Catalog Design"
                                name="catalog_design_id"
                                rules={[{ required: true, message: 'Design is required!' }]}
                                className='mb-0'
                            >
                                <SelectDebounce
                                    options={fetchCatalogDesign?.data?.data || []}
                                    placeholder='Please select catalog design...'
                                    onChangeDebounceValues={(values: string) => onSearchApi(values)}
                                    isFetching={fetchCatalogDesign.isLoading}
                                    loading={fetchCatalogDesign.isLoading}
                                    fieldNames={{
                                        value: 'id',
                                        label: 'name'
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default MappingReview