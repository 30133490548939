import React, { createContext, useContext, useState } from 'react'


const TemplatesContext = createContext<any>(null)
const TemplatesProvider = ({ children }: any) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenModalHistoryCL, setIsOpenModalHistoryCL] = useState(false);
  const [recordEditName, setRecordEditName] = useState('')
  const [record, setRecord] = useState<any>()
  const [type, setType] = useState<any>()
  const [row, setRow] = useState({
    selectedRowKeys: [],
    selectedRows: []
  })
  const handleOpenModal = (value: boolean, record: any, type: any) => {
    setOpenModal(value)
    setRecordEditName(record)
    setRecord(record)
    setType(type)
  }

  const value: any = {
    setOpenModal,
    isOpenModalHistoryCL,
    setIsOpenModalHistoryCL,
    isOpenModal,
    handleOpenModal,
    recordEditName,
    setRow,
    setRecordEditName,
    row,
    record,
    setRecord,
    type
  };

  return <TemplatesContext.Provider value={value}>
    {children}
  </TemplatesContext.Provider>
}

const useTemplatesContext = () => useContext(TemplatesContext);
export { TemplatesContext, TemplatesProvider, useTemplatesContext }
