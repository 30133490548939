import React from 'react'
import { Link } from 'react-router-dom'

import './styles.css'

const Error404 = () => {
    return (
        <div className='body404'>
            <div className='scene404'>
                <div className='box404'>
                    <div className='box__face404 front'>4</div>
                    <div className='box__face404 back'>0</div>
                    <div className='box__face404 right'>4</div>
                    <div className='box__face404 left'>0</div>
                    <div className='box__face404 top'>Error</div>
                    <div className='box__face404 bottom'>0</div>
                </div>
                <div className='shadow404'></div>
            </div>
            <div className='desc404'>
                <h2 className='h2404'>Oops page not found!</h2>
                <Link to='/catalog/catalog-list'>
                    <button className='button404'>BACK TO HOME PAGE</button>
                </Link>
            </div>
        </div>
    )
}

export default Error404