import { FetcherResponse } from 'interfaces/fetcher'
import { axiosInstance } from 'utils/fetcher'


export type CreateReviewRequest = {
    id?: any
    catalog_design_id?: any
    [x: string]: any
}

const createReviewRequest = async (params: CreateReviewRequest): Promise<FetcherResponse<any>> => {
    const { id, ...rest } = params || {}
    const data = await axiosInstance.post(`api/v1/catalog-mockup-templates/${id}/review-request`, rest)

    return data.data
}

export default createReviewRequest
