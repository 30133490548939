import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';

import './styles.css';

type Props = {
    title?: string;
    href?: string | false;
    stepBack?: number;
};

const BackButton = ({ title, href, stepBack }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnClick = () => {
        if (stepBack) {
            return navigate(stepBack);
        }

        if (href === false) return

        if (!!location?.state?.stepBack) {
            return navigate(location?.state?.stepBack);
        } else {
            navigate(href || '/');
        }
    };

    return (
        <Row>
            <Col>
                <Button
                    type='default'
                    className="back"
                    onClick={handleOnClick}
                    icon={<AiOutlineArrowLeft />}
                >
                    <span>{title || 'Back'}</span>
                </Button>
            </Col>
        </Row>
    );
};

export default BackButton;
