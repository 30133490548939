import Breadcrumb from 'antd/lib/breadcrumb';
import { PageTitle } from 'contexts/page-data';
import { faCube } from '@fortawesome/free-solid-svg-icons'; // <-- import styles to be used
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = { title: string | '' }

const Header = ({ title }: Props) => {

  return (
    <div>
      <Breadcrumb style={{ margin: "20px 0" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/templates/list'>Templates</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle><FontAwesomeIcon icon={faCube} size={'1x'} /><span style={{ fontSize: '22px' }}> {title}</span></PageTitle>
    </div>
  )
}

export default Header