import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constant/query';
import getDetailTemplates, { GetDetailTemplates } from 'modules/template/apis/get-detail-templates';

type UseGetTemplatesDetail = {
    enabled?: boolean;
} & GetDetailTemplates

const useGetDetailTemplateList = (params: UseGetTemplatesDetail = {}) => {
    const { enabled = true, ...rest } = { ...params }

    return useQuery({
        queryKey: [QUERY_KEYS.GET_TEMPLATE_DETAIL, rest],
        queryFn: () => getDetailTemplates(rest),
        enabled: !!enabled
    })
};

export default useGetDetailTemplateList;