import { useMemo } from 'react'
import { Card, Tag } from 'antd'
import { omit, pick } from 'lodash'
import { BasicTemplate } from 'modules/template/apis/interfaces/templates'
import { snakeToTitleCase } from 'modules/template/helpers/to-camel-case'

type Props = {
    value: BasicTemplate['layers']
    style?: React.CSSProperties
}

const DisplayDesignLayer = ({ value, style }: Props) => {
    const sortValues = useMemo(() => {
        const newSortValues = value?.map((v) => {
            const keys = ['layer_name', 'width', 'height', 'dpi']
            const layerName = pick(v, keys)
            const rest = omit(v, keys)

            return { ...layerName, ...rest }
        })

        return newSortValues
    }, [value])

    return (
        <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', ...style }}>
            {
                sortValues?.map((item: any, index: number) => {
                    const layerName = pick(item, ['layer_name'])
                    const omitLayerName = omit(item, ['layer_name']);

                    return (
                        <div key={index}>
                            <Card
                                size='small'
                                title={<div className='text-center'>{layerName?.layer_name || ''}</div>}
                                styles={{
                                    header: {
                                        minHeight: 25
                                    }
                                }}
                            >
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 6 }}>
                                    {Object.entries(omitLayerName).map(([key, value]: [string, any]) => {
                                        return (
                                            <Tag
                                                key={key}
                                                color={item.color}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 500,
                                                        textTransform: 'capitalize',
                                                        marginRight: 2
                                                    }}>
                                                    {snakeToTitleCase(key)}:
                                                </span>
                                                <span>{typeof value === 'boolean' ? value + '' : value}</span>
                                            </Tag>
                                        )
                                    })}
                                </div>
                            </Card>
                        </div>
                    );
                })
            }
        </div>
    )
}
export default DisplayDesignLayer