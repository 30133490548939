import { useCallback, useState } from 'react'
import { debounce } from 'lodash'

import SelectCustom, { SelectCustomProps } from '../select-custom'

import './styles.css'

type Props = {
    onChangeDebounceValues?: (value: string) => void
    isFetching?: boolean
} & SelectCustomProps

const SelectDebounce = (props: Props) => {
    const { onChangeDebounceValues, isFetching, ...restProps } = props
    const [searchValue, setSearchValue] = useState<string>('')

    const debouncedSearch = useCallback(debounce((value: string) => {
        if (!onChangeDebounceValues) return

        return onChangeDebounceValues(value)
    }, 1000), [])

    const handleSearch = (value: any) => {
        setSearchValue(value)
        debouncedSearch(value);
    }

    return (
        <SelectCustom
            isSearchApi={true}
            searchValue={searchValue}
            onSearch={handleSearch}
            popupClassName={isFetching ? "custom-infinity-select-loading" : ""}
            loading={isFetching}
            {...restProps}
        />
    )
}

export default SelectDebounce