import { HTMLAttributes } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { RxOpenInNewWindow } from 'react-icons/rx'
import { Tooltip } from 'antd'
import { clsx } from 'clsx';

import LoadingImage from '../loading-image';

import styles from './styles.module.scss'

type Props = {
  itemId?: any,
  originLink?: string,
  children: any,
  iconClose?: boolean,
  iconReview?: boolean,
  index?: string | number,
  onRemove?: (id: any) => void,
  status?: string,
  isZoom?: boolean,
  iconSize?: 'small' | 'middle',
  loading?: boolean,
  textDelete?: string
} & HTMLAttributes<HTMLDivElement>

const OverlayImage = ({ children, iconClose = false, iconReview = true, index, originLink, onRemove, status, isZoom = true, iconSize = 'small', itemId, loading, textDelete = 'Delete', ...otherProps }: Props) => {
  const handleRemoveImg = (id: any) => {
    onRemove && onRemove(id)
  }


  return (
    <div {...otherProps} className={styles.group} >
      <div
        className={clsx({ [styles.isZoom]: !!isZoom && (children.props.src !== 'error') })}
        style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {children}
        {loading && (
          <div className={styles.skeletonImage}>
            <LoadingImage />
          </div>
        )}
      </div>
      {
        (!!iconClose || !!originLink && children.props.src !== 'error') &&
        <div className={clsx({ [styles.overlay]: true, [styles.overlayZoom]: !!isZoom })}>
          <div className={styles.groupIcon}>
            {iconClose &&
              <Tooltip title={textDelete} placement='right'>
                <span className={styles.iconClose} onClick={() => handleRemoveImg(itemId)}
                  style={iconSize === 'small' ? { padding: '0 8px' } : { padding: '5px 12px' }}
                >
                  <AiOutlineClose style={{ verticalAlign: '-0.3rem', fontSize: iconSize === 'small' ? 12 : 20 }} />
                </span>
              </Tooltip>
            }
            {
              (iconReview && !!originLink) &&
              <Tooltip title='View the image in a new tab' placement='right'>
                <a className={clsx({ [styles.iconReviewSmall]: iconSize === 'small', [styles.iconReview]: iconSize !== 'small' })} href={originLink} target='_blank'
                  style={iconSize === 'small' ? { padding: '0 8px' } : { padding: '5px 12px' }}
                >
                  <RxOpenInNewWindow style={{ marginTop: '-0.15rem', fontSize: iconSize === 'small' ? 12 : 20 }} />
                </a>
              </Tooltip>
            }
            {
              (!!index || index === 0) &&
              <div className={styles.index}>
                POS: {index}
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default OverlayImage