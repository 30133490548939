import { FetcherResponse } from 'interfaces/fetcher'
import {axiosInstance} from 'utils/fetcher'

const logout = async (): Promise<FetcherResponse<any>> => {
  const { data: responseData } = await axiosInstance.post<
    FetcherResponse<any>
  >('api/v1/sign-out')

  return responseData
}

export default logout
