export enum catalogFilter {
    Deploy = 'modalDeploy',
    BulkEdit = 'modalBulkEdit',
    ReplaceCharacter = 'modalReplaceCharacter',
    UpdateForce = 'modalUpdateForce',
    DeploySKU = 'modalDeploySKU',
    UpdatePartial = 'modalUpdatePartial',
    UpdateImage = 'modalUpdateImage',
    UpdateStatus = 'modalUpdateStatus',
    UpdateDelete = 'modalUpdateDelete',
    AutoDeploy = 'modalAutoDeploy',
    AutoForceUpdate = 'modalAutoForceUpdate',
    AutoUpdateNameSKU = 'modalAutoUpdateNameSKU',
    AutoDeployCreate = 'modalAutoDeployCreate',
    AutoDeployDelete = 'modalAutoDeployDelete',
    MoreDelete = 'modalMoreDelete',
    DeleteNewDesign = 'deleteNewDesign',
    CustomLabel = 'modalCustomLabel',
    History = 'modalHistoryCustomLabel',
    UpdateAmz = 'modalUpdateAmz',
    UpdateAttribute = 'modalUpdateAttribute',
    UpdateDesign = 'modalUpdateDesign',
}

export enum keyModal {
    EditNameTable = 'modalEditNameTable'
}

export enum KeyFilter {
    SearchKeyWord = 'search_keyword',
    SearchInput = 'search_input',
    DateFrom = 'filter_date_from',
    DateTo = 'filter_date_to',
    ActualCategory = 'actual_category_id',
    Trademark = 'is_tm',
    Store = 'sites',
    CustomLabel0 = 'custom_label_0',
    CustomLabel1 = 'custom_label_id',
    CustomLabel4 = 'custom_label_4',
    NumberOrder = 'number_order',
    QuantityItemOrder = 'quantity_item_order',
    OriginNumberOrder = 'origin_number_order',
    OriginQuantityItemOrder = 'origin_quantity_item_order',
    StatusRequestMockup = 'status_request_mockup',
    ImportRequest = 'request_id',
    DesignBy = 'design_by',
    TypeAmz = 'type_amz',
    MBA = 'mba_id',
    Color = 'tag_id',
    ErrorUrl = 'is_access_denied',
    Platform = 'platforms',
    CreatedBy = 'created_by',
    TypeDeploy = 'type_deploy',
    StatusDeploy = 'status_deploy',
    LimitWarning = 'limited_warning',
    GroupStore = 'group_store',
    GroupSite = 'taxonomy_code',
    User = 'user_id',
    Search = 'q',
    FilterType = 'type',
    GroupBy = 'group_id',
    PathId = 'path_id',
    Type = 'type',
    TrademarkKeyword = 'is_trademark',
    RequestCode = 'request_code',
    Status = 'status',
    SessionUser = 'user_id',
    UpdatedAt = 'updated_at',
    UsingApi = 'is_ready_to_crawl',
    CreatedDate = 'created_date',
    SKU_CODE = 'sku_code',
    Attribute = 'attribute_id'
}

export enum typeSelect {
    ALL = 'ALL',
    NOT = 'NOT'
}
export enum TypeVendor {
    Vendor = 'Vendor',
    ProductType = 'Product Type'
}
export enum TypeFilter {
    ALL = 'ALL',
    NOT = 'NOT'
}
export enum TypeRedirect {
    Deploy = 'deploy',
    DeploySkuName = 'deploySkuName',
    UpdateForce = 'updateForce',
    UpdatePartial = 'updatePartial',
    UpdateImage = 'updateImage',
    UpdateStatus = 'updateStatus',
    Delete = 'delete',
    AutoDeploy = 'autoDeploy',
    AutoUpdateSkuName = 'autoDeploySkuName',
    AutoUpdateForce = 'autoUpdateForce',
    AutoDelete = 'autoDelete',
    AutoDeployCreateSite = 'autoDeployCreateSite',
    UpdateAmz = 'updateAmz',
    UpdateAttribute = 'updateAttribute',
    UpdateDesign = 'updateDesign'
}

export enum TypeDeploy {
    AutoDeploy = 'auto-deploy',
    DeployEditSku = 'deploy-edit-sku',
    DeployPartial = 'deploy-partial',
    DeployStatus = 'deploy-status',
    AutoDeployEditSku = 'auto-deploy-edit-sku',
    AutoDeployAfterCreateSite = 'auto-deploy-after-create-site'
}
export enum Platform {
    Amazon = 'amazon',
    Canawan = 'canawan',
    Ebay = 'ebay',
    Etsy = 'etsy',
    Magento = 'magento',
    Shopbase = 'shopbase',
    Shopify = 'shopify',
    Woocommerce = 'woocommerce',
    Artfire = 'artfire',
    Bonanza = 'bonanza',
    Mercari = 'mercari',
    Khakim = 'khakim',
    Fulfill = 'fulfill',
    Bigcommerce = 'bigcommerce',
    DodgePrint = 'dodge-print'
}
