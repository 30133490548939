import { FetcherResponse } from "../../../interfaces/fetcher"
import { axiosInstance } from "../../../utils/fetcher"

const getUser = async (id: any): Promise<FetcherResponse<any>> => {
  const { data } = await axiosInstance.get<
    FetcherResponse<any>
  >(`api/v1/users/${id}`)
  return data
}

export default getUser
