import axios from 'axios'
import { API_UPLOAD_URL, UPLOAD_TOKEN } from 'components-v2/constants'
import { FetcherResponse } from 'interfaces/fetcher'


export type CreateUploadMockup = {
    id?: any
    formData?: any
    [x: string]: any
}

const createUploadMockup = async (params: CreateUploadMockup): Promise<FetcherResponse<any>> => {
    const { id, formData } = params || {}
    const data = await axios.post(
        `${API_UPLOAD_URL}api/v1/catalog-mockup-templates/${id}/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${UPLOAD_TOKEN}`
        }
    })

    return data.data
}

export default createUploadMockup
