
import { useEffect, useMemo, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { CiEdit } from "react-icons/ci";
import { GrClose } from "react-icons/gr";
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { QUERY_KEYS } from 'constant/query';
import { isEmpty } from 'lodash';
import { notify } from 'modules/catalog/components/notify-component';
import createTemplates, { CreateTemplatesParams } from 'modules/template/apis/create-templates';
import { BasicTemplate } from 'modules/template/apis/interfaces/templates';
import updateTemplates, { UpdateTemplatesParams } from 'modules/template/apis/update-templates';
import SelectCustom from 'modules/template/components/select-custom';
import { useQueryRequest } from 'modules/template/core/query-request-provider';

import UploadFile from './upload';

type ModalTemplateProps = {
    data?: BasicTemplate
};

const ModalTemplate = ({ data }: ModalTemplateProps) => {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const { actualCategory = [] } = useQueryRequest();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const optionsActualCategory = useMemo(() => actualCategory?.map((c) => ({ ...c, label: c?.name, value: c?.id })), [actualCategory, isModalOpen]);

    const empty = isEmpty(data);
    const buttonProps = empty ? {} : { ghost: true }

    const mutationCreate = useMutation({
        mutationFn: (params: CreateTemplatesParams) => createTemplates(params),
    })
    const mutationUpdate = useMutation({
        mutationFn: (params: UpdateTemplatesParams) => updateTemplates(params),
    })

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = async (values: any) => {
        const layerWatch = form.getFieldValue('layers');
        if (layerWatch?.filter((layer: any) => layer?.is_design).length > 1) {
            return toast.warning('Only one layer can be design');
        }

        if (empty) {
            const formData = new FormData();
            formData.append('name', values?.name);
            formData.append('title', values?.title);
            formData.append('number_mockups', values?.number_mockups);
            formData.append('category_id', values?.category_id);
            formData.append('mockup_file', values?.mockup_file);
            formData.append('enable', values?.enable ? '1' : '0');
            formData.append('layers', JSON.stringify(values?.layers));
            const result = await mutationCreate.mutateAsync(formData as any);
            notify(result, 'Create');
            if (result?.success) {
                queryClient.invalidateQueries([QUERY_KEYS.GET_TEMPLATE_LIST]);
                setIsModalOpen(false);
            }
        } else {
            const result = await mutationUpdate.mutateAsync({
                id: data?.id,
                name: values?.name,
                title: values?.title,
                number_mockups: values?.number_mockups,
                enable: values?.enable ? '1' : '0',
                category_id: values?.category_id,
                layers: values?.layers,
            });
            notify(result, 'Update');
            if (result?.success) {
                queryClient.invalidateQueries([QUERY_KEYS.GET_TEMPLATE_DETAIL]);
                setIsModalOpen(false);
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleAfterClose = () => {
        form.resetFields();
    }

    useEffect(() => {
        if (!empty) {
            form.setFieldsValue({
                name: data?.name,
                number_mockups: data?.number_mockups,
                enable: data?.enable,
                layers: data?.layers,
                category_id: data?.category_id,
                title: data?.title,
            });
        }
    }, [empty, data, isModalOpen])

    return (
        <>
            <Button
                type={empty ? "primary" : 'primary'}
                onClick={showModal}
                icon={empty ? <AiOutlinePlus /> : <CiEdit />}
                children={empty ? 'Create' : ''}
                {...buttonProps}
            />
            <Modal
                title={empty ? "Create Template" : "Edit Template"}
                open={isModalOpen}
                onOk={form.submit}
                onCancel={handleCancel}
                confirmLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
                afterClose={handleAfterClose}
                width={800}
                okText={empty ? 'Create' : 'Update'}
            >
                <Form
                    labelAlign="left"
                    layout='vertical'
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Row gutter={[16, 16]} style={{ margin: 0 }}>
                        <Col span={24}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Name is required!' }]}
                                className='mb-0'
                            >
                                <Input placeholder='Please enter name...' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Title"
                                name="title"
                                className='mb-0'
                            >
                                <Input placeholder='Please enter title...' />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                label="Number Mockups"
                                name="number_mockups"
                                rules={[{ required: true, message: 'Number mockups is required!' }]}
                                className='mb-0'
                            >
                                <Input type='number' min={0} placeholder='Please enter number mockup...' />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                label="Category"
                                name="category_id"
                                className='mb-0'
                            >
                                <SelectCustom
                                    placeholder='Please select category...'
                                    options={optionsActualCategory || []}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7} className='text-end'>
                            <Form.Item
                                label="Enable"
                                name="enable"
                                valuePropName='checked'
                                className='mb-0'
                                initialValue={true}
                            >
                                <Checkbox className='text-start w-100'>
                                    <span style={{ fontWeight: 500 }}>Enable</span>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {empty &&
                            <Col span={24}>
                                <Form.Item
                                    label="Choose Mockup File"
                                    name="mockup_file"
                                    className='mb-0'
                                    rules={[{ required: true, message: 'Mockup file is required!' }]}
                                >
                                    <UploadFile accept='.psd' />
                                </Form.Item>
                            </Col>
                        }

                        <Col span={24}>
                            <div
                                style={{ maxHeight: 400, overflow: 'auto' }}
                            >
                                <Form.List name="layers" initialValue={[{}]}>
                                    {(fields, { add, remove }) => (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Row>
                                                {fields.map((field, index) => (
                                                    <Col span={12} className='mb-2 pe-2' key={field.key}>
                                                        <Card
                                                            size='small'
                                                        >
                                                            <Row gutter={[16, 16]}>
                                                                {index === 0}
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        cursor: 'pointer',
                                                                        right: 8,
                                                                        top: 0,
                                                                        zIndex: 1,
                                                                        width: 20,
                                                                    }}>
                                                                    <div
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                        }}
                                                                    >
                                                                        <GrClose />
                                                                    </div>
                                                                </div>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        label="Layer Name"
                                                                        name={[field.name, 'layer_name']}
                                                                        className='mb-0'
                                                                        rules={[{ required: true, message: 'Layer name is required!' }]}
                                                                    >
                                                                        <Input placeholder='Please enter name...' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label="Width"
                                                                        name={[field.name, 'width']}
                                                                        className='mb-0'
                                                                        rules={[{ required: true, message: 'Width is required!' }]}
                                                                    >
                                                                        <Input type='number' min={0} placeholder='Please enter width...' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label="Height"
                                                                        name={[field.name, 'height']}
                                                                        className='mb-0'
                                                                        rules={[{ required: true, message: 'Height is required!' }]}
                                                                    >
                                                                        <Input type='number' min={0} placeholder='Please enter height...' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label="DPI"
                                                                        name={[field.name, 'dpi']}
                                                                        className='mb-0'
                                                                        rules={[{ required: true, message: 'DPI is required!' }]}
                                                                    >
                                                                        <Input type='number' min={0} placeholder='Please enter dpi...' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label=""
                                                                        name={[field.name, 'is_design']}
                                                                        valuePropName='checked'
                                                                        className='mb-0'
                                                                        style={{ marginTop: 30 }}
                                                                        initialValue={index === 0 ? true : false}
                                                                    >
                                                                        <Checkbox>
                                                                            <span style={{ fontWeight: 500 }}>Is Design</span>
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <Row className='d-flex justify-content-center'>
                                                <Button
                                                    type="primary"
                                                    onClick={() => add()}
                                                    style={{ width: 300 }}
                                                    icon={<AiOutlinePlus />}
                                                >
                                                    Add Item
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Form.List>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalTemplate;