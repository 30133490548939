import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from "./styles.module.scss"


const LayoutSplashScreen = () => {
  const antIcon = <LoadingOutlined className={styles.icon} spin />;

  return (
    <div className={styles.wrapperLoading}>

      <div className={styles.loader}>
        <span></span>
        <span></span>
        <span></span>
        {/* <span>L</span>
        <span>o</span>
        <span>a</span>
        <span>d</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
        <span>.</span>
        <span>.</span>
        <span>.</span> */}
      </div>
    </div>
  )
}

export default LayoutSplashScreen