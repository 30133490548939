import { Checkbox, Col, Row } from "antd";

type Props = {
    quantity?: number
    menu?: React.ReactElement
    onCheckAll?: (checked?: any) => void
    onCheckNot?: (checked?: any) => void
    checkedAll?: boolean
    checkedNot?: boolean
}

const DropdownRender = ({ menu, onCheckAll, onCheckNot, checkedAll, checkedNot, quantity }: Props) => {

    return (
        <div>
            <Row className="mx-1 mb-2">
                <Col span={12}>
                    <Checkbox
                        checked={checkedAll}
                        onChange={onCheckAll}
                        disabled={quantity === 0}
                    >
                        ALL ({quantity})
                    </Checkbox>
                </Col>
                <Col span={12} className="text-end">
                    <Checkbox
                        checked={checkedNot}
                        onChange={onCheckNot}
                        disabled={quantity === 0}
                    >
                        NOT
                    </Checkbox>
                </Col>
            </Row>
            <div>
                {menu}
            </div>
        </div>
    )
}

export default DropdownRender