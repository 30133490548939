import { FetcherResponse } from 'interfaces/fetcher'
import { axiosInstance } from 'utils/fetcher'

import { BasicTemplate } from '../interfaces/templates'

export type GetDetailTemplates = {
    id?: any
    [x: string]: any
}

const getDetailTemplates = async (params: GetDetailTemplates): Promise<FetcherResponse<BasicTemplate>> => {
    const { id, ...rest } = params || {}
    const data = await axiosInstance.get(`${process.env.REACT_APP_API_URL}api/v1/catalog-mockup-templates/${id}`, {
        params: rest
    })

    return data.data
}

export default getDetailTemplates
