import { FetcherResponse } from 'interfaces/fetcher'
import { axiosInstance } from 'utils/fetcher'

import { LoginPayload } from './interface'

const login = async (
  loginPayload: LoginPayload,
): Promise<FetcherResponse<any>> => {
  const { data: responseData } = await axiosInstance.post<
    FetcherResponse<any>
  >('api/v1/sign-in', loginPayload, {
    headers: {
      Authorization: null
    }
  })

  return responseData
}

export default login
