import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { Form, FormInstance, Space } from "antd"
import { FieldsProp } from "components/filter/filter"

import { compactFields, TypeCompactFields } from "./compact-fields"

export type CompactFieldsProps = {
    compact?: Partial<FieldsProp>[]
    compactKey?: string[]
    form?: FormInstance
    [key: string]: any
}

const CompactFields = (props: CompactFieldsProps) => {
    const [searchParams] = useSearchParams()
    const { compact, compactKey = [], form } = props || {}
    const isCompactValue = compactKey.length === 1

    useEffect(() => {
        const searchValues = Object.fromEntries(searchParams.entries())
        if (isCompactValue) {
            const fieldName = compactKey[0]
            const valueKey = searchValues[fieldName]?.split(',') || []
            const fieldValue = {} as any
            compact?.forEach((item, index) => {
                const { key = '' } = item || {}
                fieldValue[key] = valueKey[index]
            })

            form?.setFieldValue(fieldName, fieldValue)
        } else {
            compactKey?.forEach((key) => {
                const i = compact?.find((item) => item?.key === key)
                form?.setFieldValue(key, searchValues?.[key] || i?.defaultValue)
            })
        }
    }, [compactKey, searchParams])

    return (
        <Space.Compact>
            {compact?.map((item, index: number) => {
                const { key, ...rest } = item
                const type = item?.type as TypeCompactFields | undefined
                if (!type) return null
                const Child = compactFields.find((f) => f.key.includes(type))?.component ?? (() => <div></div>)
                const formKey = isCompactValue ? [...compactKey, key] : key

                return <Form.Item
                    name={formKey}
                    key={index}
                    style={{ marginBottom: 4 }}
                >
                    <Child
                        {...rest}
                        style={index === 0 ? { width: '100px' } : {}}
                    />
                </Form.Item>
            })}
        </Space.Compact>
    )
}

export default CompactFields