import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import WaitingAuthMicrosoft from 'modules/auth/auth-waiting-ms';
import { AuthInit, useAuth } from 'modules/auth/contexts/auth-context';

import Login from '../modules/auth/login';
import PrivateRoutes from './private-route';


const AppRoutes = () => {
    const { auth } = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={
                        <AuthInit>
                            <Outlet />
                        </AuthInit>
                    }
                >
                    {!_isEmpty(auth) ? (
                        <Route>
                            <Route index path="/*" element={<PrivateRoutes />} />
                        </Route>
                    ) : (
                        <Route>
                            <Route path="login/*" element={<Login />} />

                            <Route path="sign-in/*" element={<WaitingAuthMicrosoft />} />
                            <Route path="/*" element={<Navigate to="login" />} />
                        </Route>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
