import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { KeyAllNotSearch } from '../components/select-checkbox'
import { FieldsProp } from '../filter'
import { TypeKeyFormItem } from '../filter-field/fields'
import DisplayItem from './item'

type Props = {
    fields: FieldsProp[]
    onRemove?: ({ key, value, options }: { key?: string, value?: string, options?: any }) => void
}

export type QueryValues = {
    key: string
    queryKey: string[]
    value: string[]
}

const DisplayCurrentFilter = ({ fields, onRemove }: Props) => {
    const ALL = KeyAllNotSearch.AllSearch
    const [searchParams] = useSearchParams()
    const search = new URLSearchParams(searchParams)
    const allQuery = Object.fromEntries(search)
    const compactTwoValue = fields.filter((f) => f.type === TypeKeyFormItem.Compact && Number(f?.compactKey?.length === 2)) || []
    const compactOneValue = fields.filter((f) => f.type === TypeKeyFormItem.Compact && Number(f?.compactKey?.length === 1)) || []
    const actualKeyFields = fields.map((f) => {
        if (f.compactKey) {
            return f.compactKey.map((key) => key)
        } else {
            return f.key
        }
    }).flat()

    const query = useMemo(() => {
        const tempQuery = allQuery
        Object.keys(allQuery).forEach((key) => {
            if (
                !actualKeyFields.includes(key) &&
                ![KeyAllNotSearch.AllSearch, KeyAllNotSearch.ElseSearch].includes(key as any)
            ) {
                delete tempQuery[key]
            }
        })

        return tempQuery
    }, [allQuery])

    const queryValues = useMemo(() => {
        const arrQuery = Object.entries(query) || []
        const values = [] as QueryValues[]
        arrQuery.forEach(([key, value]) => {
            if (!value) return
            const twoValueKey = compactTwoValue.map((e) => e.compactKey)?.flat()
            if (twoValueKey.includes(key)) {
                compactTwoValue.forEach((f) => {
                    const k = query?.[f?.compact?.[0]?.key]
                    const v = query?.[f?.compact?.[1]?.key]
                    if (values.findIndex((v) => v.key === k) > -1 || !k) return
                    values.push({
                        key: k,
                        value: [v],
                        queryKey: [f?.compact?.[0]?.key, f?.compact?.[1]?.key]
                    })
                })
            } else {
                values.push({
                    key,
                    queryKey: [key],
                    value: compactOneValue.map(c => c?.key).includes(key) ? [value] : value?.split(','),
                })
            }
        })

        const transformAllElse = values?.map((v) => {
            if (v.key === ALL) {
                const newV = v.value.map((value) => {
                    return {
                        key: value,
                        queryKey: [value],
                        value: [KeyAllNotSearch.AllValue]
                    }
                })

                return newV
            } else {
                return v
            }

        })

        return transformAllElse.flat()
    }, [query, compactTwoValue, compactOneValue, actualKeyFields])

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
            {queryValues.map((q) => {
                const field = fields.find((f) => {
                    const i = f.compactKey?.find((key) => {
                        return q?.queryKey.includes(key)
                    })

                    return f.key === q?.key || !!i
                })

                return (
                    <div key={q?.key}>
                        <DisplayItem
                            q={q}
                            field={field}
                            queryValues={queryValues}
                            onRemove={onRemove}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default DisplayCurrentFilter